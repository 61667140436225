import axios from "axios";

import Cookies from "js-cookie";
import { ModuleName } from "../utils/globalVariables";

const Http = axios.create({
  baseURL: `https://edogoverp.com/mdaappraisal`,
});

Http.interceptors.request.use(
  function (config) {
    const appToken = Cookies.get(ModuleName + "_AppToken");

    config.headers.Authorization = appToken ? `Bearer ${appToken}` : "";
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default Http;
