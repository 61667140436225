
import {
    SUBMIT_SUPERVISOR_SCORE,
    SUBMIT_SUPERVISOR_SCORE_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    submitSupervisorScore: {},
    isLoading: false,
  };


const submitSupervisorScoreReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SUBMIT_SUPERVISOR_SCORE:
        return {
          ...state,
          submitSupervisorScore: action.payload,
        };
  
      case SUBMIT_SUPERVISOR_SCORE_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default submitSupervisorScoreReducer;