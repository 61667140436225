import {
    GET_MY_APPROVAL_ROUTE,
    GET_MY_APPROVAL_ROUTE_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    approvalRoute: [],
    isLoading: false,
  };
  
  const approvalRouteReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_MY_APPROVAL_ROUTE:
        return {
          ...state,
          approvalRoute: action.payload,
        };
      case GET_MY_APPROVAL_ROUTE_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default approvalRouteReducer;