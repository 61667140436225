import {
  GET_COMPETENCY_CATEGORY,
  GET_COMPETENCY_CATEGORY_LOADER,
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  competencyCategory: [],
  isLoading: false,
};

const competencyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_COMPETENCY_CATEGORY:
      return {
        ...state,
        competencyCategory: action.payload,
      };
    case GET_COMPETENCY_CATEGORY_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default competencyReducer;
