import { combineReducers } from "redux";
import dashboardDetailsReducer from "./reducers/dashboardDetailsReducer";
import rolesReducer from "./reducers/rolesReducer";
import competencyReducer from "./reducers/competencyReducer";
import performanceDetailsReducer from "./reducers/performanceDetailsReducer"
import GoalsReducer from "./reducers/GoalsReducer"
import submitScoreReducer from "./reducers/submitScoreReducer";
import submitSupervisorScore from "./reducers/submitSupervisorScoreReducer";
import submitApprovalReducer from "./reducers/submitSupervisorApprovalReducer";
import submitSupervisorApprovalReducer from "./reducers/submitSupervisorScoreReducer";
import approvalRouteReducer from "./reducers/approvalRouteReducer";
import {GetDevelopmentNeedsReducer, SubmitDevelopmentNeedsReducer, UpdateDevelopmentNeedsReducer, DeleteDevelopmentNeedsReducer } from "./reducers/DevelopmentNeedsReducer";

const rootReducer = combineReducers({
  dashboard: dashboardDetailsReducer,
  performanceDetails: performanceDetailsReducer,
  goals: GoalsReducer,
  submitScore: submitScoreReducer,
  submitSupervisorScore: submitSupervisorScore,
  roles: rolesReducer,
  submitApproval: submitApprovalReducer,
  submitSupervisorApproval: submitSupervisorApprovalReducer,
  competencyReducer,
  GetDevelopmentNeedsReducer,
  SubmitDevelopmentNeedsReducer,
  UpdateDevelopmentNeedsReducer,
  DeleteDevelopmentNeedsReducer,
  approvalRouteReducer
});

export default rootReducer;
