import React from "react";

import { IoMdClose } from "react-icons/io";

import Spinner from "../common/spinner/spinner";

const ExpectationsModal = ({
  isFetchingExpectations,
  closeModal,
  expectations,
}) => {
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box w-100 ">
        <div className="content cloak">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">S/N</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Initial Expectations</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Final Expectations</p>
                </div>
                <div className="th">
                  <p className="san-sherif">MdaName</p>
                </div>
              </div>
            </div>
            <div className="table-body">
              {expectations?.map((item, index) => (
                <div className="table-row" key={index}>
                  <div className="td">{index + 1}</div>
                  <div className="td">{item?.initialExpectation}</div>
                  <div className="td">
                    {item?.actualExpectation ? item?.actualExpectation : "NIL"}
                  </div>
                  <div className="td">{item?.mdaName}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpectationsModal;
