import React, { useEffect, useState } from "react";

import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";

import Spinner from "../common/spinner/spinner";
import TextareaInput from "../inputs/TextareaInput";
import TextInput from "../inputs/TextInput";
import SelectInput from "../inputs/SelectInput";

const GoalForm = ({ closeModal, handleSave, loading, performanceStages }) => {
  const [inputData, setInputData] = useState();
  const [expectationList, setExpectationList] = useState([]);
  const [refinedStages, setRefinedStages] = useState([]);

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const clearForm = () => {
    document.getElementById("modal-form").reset();
  };

  useEffect(() => {
    if (performanceStages.length > 0) {
      setRefinedStages(performanceStages);
    }
  }, [performanceStages]);

  const handleChangeInitialExpectation = (e, item) => {
    const value = e.target.value;
    const itemObject = expectationList.find((obj) => obj.id === item?.id);
    const index = expectationList.indexOf(itemObject);
    const newList = [...expectationList];

    newList[index] = {
      id: item?.id,
      initialExpectation: value,
      stageId: newList[index]?.stageId,
    };

    setExpectationList(newList);
  };

  const handleChangeStage = (e, item) => {
    const value = e.target.value;
    const itemObject = expectationList.find((obj) => obj.id === item?.id);
    const index = expectationList.indexOf(itemObject);
    const newList = [...expectationList];

    newList[index] = {
      id: item?.id,
      initialExpectation: newList[index]?.initialExpectation,
      stageId: Number(value),
    };

    setExpectationList(newList);
  };

  const handleAddKpi = (e) => {
    e.preventDefault();

    setExpectationList((prev) => [
      ...prev,
      { id: uuidv4(), initialExpectation: "", stageId: "" },
    ]);

    // to be continued...
    // if (expectationList.length > 0) {
    //   const newExpectationList = [...expectationList];
    //   let lastStageIdAdded =
    //     newExpectationList[newExpectationList.length - 1]?.stageId;

    //   let result = refinedStages.filter(
    //     (item) => item?.value !== lastStageIdAdded
    //   );
    //   setRefinedStages(result);
    // }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <form
          id="modal-form"
          onSubmit={(e) => {
            handleSave(e, { inputData, expectationList });
            clearForm();
          }}
        >
          <div className="content">
            <TextInput
              className="w-100 m-b-5"
              name="objective"
              label="Objective"
              type="text"
              onChange={handleChange}
              required={true}
            />
            <TextareaInput
              className="w-100 m-b-5"
              name="performanceIndicator"
              label="Performance Indicator"
              type="text"
              onChange={handleChange}
              required={true}
            />
            <TextInput
              className="w-100 m-b-5"
              name="weight"
              label="Weight"
              type="number"
              minDate={1}
              onChange={handleChange}
              required={true}
            />
            <TextInput
              className="w-100 m-b-5"
              name="priority"
              label="Sequence"
              type="number"
              minDate={1}
              onChange={handleChange}
              required={true}
            />

            <span
              style={{
                fontSize: "0.75rem",
                marginTop: "0.5rem",
                color: "#999",
              }}
            >
              Quarterly Initial Expectations
            </span>

            <div style={{ width: "100%", height: "30vh", overflow: "auto" }}>
              {expectationList.map((item, index) => (
                <div
                  key={index}
                  style={{
                    border: "1px solid #c4c4c4",
                    borderRadius: "5px",
                    marginTop: "1rem",
                    padding: "0.5rem",
                  }}
                >
                  <SelectInput
                    className="w-100 m-b-10"
                    name="schedule"
                    label="Review Schedule"
                    type="text"
                    options={refinedStages}
                    onChange={(e) => handleChangeStage(e, item)}
                    value={item?.stageId || ""}
                  />
                  <TextInput
                    placeholder="Add initial expectation"
                    key={index}
                    className="w-100 m-b-10"
                    name={`kpi+${index}`}
                    type="text"
                    value={item?.initialExpectation || ""}
                    onChange={(e) => handleChangeInitialExpectation(e, item)}
                  />
                </div>
              ))}
            </div>

            {expectationList.length !== refinedStages.length && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              >
                <button className="btn btn-large" onClick={handleAddKpi}>
                  Add Expectation
                </button>
              </div>
            )}

            <button
              type="submit"
              disabled={loading}
              className="btn btn-large w-100 m-t-10"
            >
              {loading && <Spinner />} Save Record
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GoalForm;
