
import {
    SUBMIT_APPROVAL_SUPERVISOR,
    SUBMIT_APPROVAL_SUPERVISOR_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    submitSupervisorApproval: {},
    isLoading: false,
  };


const submitApprovalReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SUBMIT_APPROVAL_SUPERVISOR:
        return {
          ...state,
          submitScore: action.payload,
        };
  
      case SUBMIT_APPROVAL_SUPERVISOR_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default submitApprovalReducer;