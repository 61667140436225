import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { feedback } from "../../utils/feedback";
import { getTotalWeight } from "../pages/Settings/AppraisalPeriod/helper";
import { IoMdClose } from "react-icons/io";
import {
  getPerformanceGoalsData,
  putSupervisorEditGoals,
} from "../../network/services";
import ExpectationsModal from "../layouts/expectationsModal";
import Spinner from "../common/spinner/spinner";

const PlanningGoalsModal = (props) => {
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [payloadObject, setPayloadObject] = useState([]);
  const [isShowExpectationsModal, setIsShowExpectationsModal] = useState(false);
  const [expectations, setExpectations] = useState([]);

  useEffect(() => {
    fetchGoalsAndCommitments();
  }, []);

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const handleExpectations = (goalExpectations) => {
    console.log("goalExpectations: ", goalExpectations);
    return setExpectations(goalExpectations);
  };

  useEffect(() => {
    if (goalsAndCommitments.length > 0) {
      const result = goalsAndCommitments.map((item) => {
        return {
          id: item?.id,
          priority: item?.priority,
          weight: item?.weight,
          performanceID: item?.performanceID,
          periodID: dashboardDetails?.periodId,
          staffID: dashboardDetails?.staffId ?? 0,
          mdaId: dashboardDetails?.mdaId,
          performanceIndicator: item?.performanceIndicator,
          objective: item?.objective,
        };
      });

      setPayloadObject(result);
    }
  }, [goalsAndCommitments]);

  const fetchGoalsAndCommitments = async () => {
    setIsLoading(true);
    try {
      const response = await getPerformanceGoalsData(props?.selectedRecord?.id);
      if (response?.status) {
        setGoalsAndCommitments(response?.data?.data);
        setIsLoading(false);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
        setIsLoading(false);
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
      setIsLoading(false);
    }
  };

  const handleChange = (e, id) => {
    const rowInQuestion = payloadObject.find((item) => item.id === id);
    const index = payloadObject.findIndex((item) => item === rowInQuestion);
    const newPayloadObject = [...payloadObject];
    newPayloadObject[index] = {
      ...rowInQuestion,
      [e.target.name]: e.target.value,
    };

    return setPayloadObject(newPayloadObject);
  };

  const handleUpdateGoal = async (id) => {
    const result = payloadObject.find((item) => item?.id === id);
    const requestObject = {
      ...result,
      weight: Number(result?.weight),
    };

    try {
      const response = await putSupervisorEditGoals(requestObject);
      if (response?.data?.code === 1) {
        props.closeModal();
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  return (
    <div className="overlay">
      <IoMdClose
        className="close-btn pointer"
        onClick={() => props.closeModal()}
      />
      <div className="modal-box w-100 ">
        <div className="content cloak" style={{ overflowX: "scroll" }}>
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th" style={{ width: "5%" }}>
                  <p className="san-sherif">S/N</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Targets/Goals/Commitments</p>
                </div>
                <div className="th">
                  <p className="san-sherif">KPIs</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Expectations</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Sequence</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Weight</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated By</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Action</p>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Spinner />
            ) : (
              <div className="table-body">
                {goalsAndCommitments?.map((item, index) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{index + 1}</div>
                    <div className="td">
                      <input
                        defaultValue={item?.objective}
                        name="objective"
                        onChange={(e) => handleChange(e, item?.id)}
                        className="table-input"
                        style={{ width: "100%", border: "1px solid #c4c4c4" }}
                      />
                    </div>
                    <div className="td">
                      <input
                        defaultValue={item?.performanceIndicator}
                        name="performanceIndicator"
                        className="table-input"
                        onChange={(e) => handleChange(e, item?.id)}
                        style={{ width: "100%", border: "1px solid #c4c4c4" }}
                      />
                    </div>
                    <div className="td actions">
                      <button
                        type="button"
                        className="btn btn-large w-100 activate"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setIsShowExpectationsModal(true);
                          handleExpectations(item?.expectations);
                        }}
                      >
                        View
                      </button>
                    </div>
                    <div className="td">{item?.priority}</div>
                    <div className="td">
                      {" "}
                      <input
                        defaultValue={item?.weight}
                        name="weight"
                        className="table-input"
                        onChange={(e) => handleChange(e, item?.id)}
                        style={{ width: "100%", border: "1px solid #c4c4c4" }}
                      />
                    </div>

                    <div className="td">
                      {item?.modifiedBy} {item?.date}{" "}
                    </div>
                    <div className="td actions">
                      <button
                        type="submit"
                        onClick={() => handleUpdateGoal(item?.id)}
                        style={{ cursor: "pointer" }}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                ))}
                <div className="table-row">
                  <div className="td"></div>
                  <div className="td"></div>
                  <div className="td"></div>
                  <div className="td"></div>
                  <div className="td"></div>
                  <div className="td">
                    {getTotalWeight(goalsAndCommitments)}
                  </div>
                  <div className="td"></div>
                  <div className="td"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {isShowExpectationsModal && (
        <ExpectationsModal
          closeModal={() => setIsShowExpectationsModal(false)}
          expectations={expectations}
        />
      )}
    </div>
  );
};

export default PlanningGoalsModal;
