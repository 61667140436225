import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";

const EditCompetencyForm = ({ closeModal, handleEdit, selectedCompetency }) => {
  const [inputData, setInputData] = useState({
    id: selectedCompetency?.id,
    categoryID: selectedCompetency?.id,
    priority: selectedCompetency?.priority,
    competency: selectedCompetency?.competency,
  });

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            className="w-100 m-b-10"
            name="competency"
            label="Competency"
            type="text"
            onChange={handleChange}
            defaultValue={selectedCompetency.competency}
          />
          <TextInput
            className="w-100 m-b-10"
            name="priority"
            label="Sequence"
            type="text"
            onChange={handleChange}
            defaultValue={selectedCompetency.priority}
          />

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={() => handleEdit(inputData)}
          >
            Edit Record
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditCompetencyForm;
