import { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RiAddLine } from "react-icons/ri";
import Cookies from "js-cookie";
import LocationDetails from "../../layouts/LocationDetails";
import {
  getPerformanceGoalDetails,
  getPerformanceGoalDetailsWithData,
  submitScore,
} from "../../../network/services";

import { feedback } from "../../../utils/feedback";
import {
  GET_PERFORMANCE_DETAILS,
  SUBMIT_SCORE,
} from "../../../redux/actions/actionsTypes";
import IsLoading from "../../common/isLoading";

const QuarterlyEntry = () => {
  const [performance, setPerformance] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const history = useHistory();

  const fetchPerformanceGoalDetailsWithData = async (data) => {
    try {
      let response;
      if (data !== null) {
        response = await getPerformanceGoalDetailsWithData(data);
      } else {
        response = await getPerformanceGoalDetails();
      }
      if (response.status === 200) {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,

          payload: response?.data?.data,
        });
        setPerformance(response?.data?.data);
      } else {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_PERFORMANCE_DETAILS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleSubmitScore = async (data, id) => {
    try {
      const response = await submitScore(data);

      if (response.data?.code === 1 || response?.data?.code === "1") {
        dispatch({
          type: SUBMIT_SCORE,
          payload: response?.data?.data,
        });

        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        history.push("/admin-portal/appraisal-dashboard");
        fetchPerformanceGoalDetailsWithData(id);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
        window.location.reload();
        dispatch({
          type: SUBMIT_SCORE,
          payload: null,
        });
      }
    } catch (e) {
      window.location.reload();
      dispatch({
        type: SUBMIT_SCORE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const periodComment = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneComment";
      case "Quarter 2 Appraisal":
        return "quaterTwoComment";
      case "Quarter 3 Appraisal":
        return "quaterThreeComment";
      case "Quarter 4 Appraisal":
        return "quaterFourComment";
    }
  };

  const periodScore = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneScore";
      case "Quarter 2 Appraisal":
        return "quaterTwoScore";
      case "Quarter 3 Appraisal":
        return "quaterThreeScore";
      case "Quarter 4 Appraisal":
        return "quaterFourScore";
    }
  };

  const dispatch = useDispatch();
  let payload = [];

  const periodDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const submit = () => {
    handleSubmitScore(payload, periodDetails.performanceId);
  };

  useEffect(() => {
    getInit();
  }, [periodDetails]);

  const getInit = async () => {
    setIsFetching(true);
    try {
      const res = await getPerformanceGoalDetailsWithData(
        parseInt(periodDetails?.performanceId)
      );
      setPerformance(res?.data?.data);
    } catch (error) {}
    setIsFetching(false);
  };

  const handleChangeScore = (goal, score) => {
    let index = payload.findIndex((value) => value.id === goal.id);
    if (index < 0) {
      payload.push({
        id: goal?.id,
        performanceId: goal?.performanceId,
        [periodComment(periodDetails?.stage)]: "",
        [periodScore(periodDetails?.stage)]: parseInt(score),
      });
    } else {
      payload[index] = {
        ...payload[index],
        [periodScore(periodDetails?.stage)]: parseInt(score),
      };
    }
  };

  // const handleChangeComment = (goal, comment) => {
  //   let index = payload.findIndex((value) => value.id === goal.id);
  //   if (index < 0) {
  //     payload.push({
  //       id: goal?.id,
  //       stageId: periodDetails?.performaceStageId,
  //       performanceId: goal?.performanceId,
  //       [periodComment(periodDetails?.stage)]: "",
  //       [periodScore(periodDetails?.stage)]: 0,
  //     });
  //   } else {
  //     payload[index] = {
  //       ...payload[index],
  //       [periodComment(periodDetails?.stage)]: comment,
  //     };
  //   }
  // };

  const handleChangeInitialExpectation = (goal, iniExpec) => {
    let index = payload.findIndex((value) => value.id === goal.id);

    if (index < 0) {
      payload.push({
        id: goal?.id,
        stageId: periodDetails?.performaceStageId,
        initialExpectation: iniExpec,
      });
    } else {
      payload[index] = {
        ...payload[index],
        initialExpectation: iniExpec,
      };
    }
  };

  const handleChangeFinalExpectation = (goal, finalExpec) => {
    let index = payload.findIndex((value) => value.id === goal.id);

    if (index < 0) {
      payload.push({
        id: goal?.id,
        stageId: periodDetails?.performaceStageId,
        finalExpectation: finalExpec,
      });
    } else {
      payload[index] = {
        ...payload[index],
        finalExpectation: finalExpec,
      };
    }
  };

  const getStageExpectation = (goalExpectations) => {
    const result = goalExpectations.find(
      (item) => item?.performanceStageId === periodDetails?.performaceStageId
    );

    return result?.initialExpectation;
  };

  const getFinalExpectation = (goalExpectations) => {
    const result = goalExpectations.find(
      (item) => item?.performanceStageId === periodDetails?.performaceStageId
    );

    return result?.actualExpectation;
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <div>
          <p>Good Day</p>
          <p>{Cookies.get("fullName")}</p>
          <p>{Cookies.get("_Role")}</p>
        </div>
        <LocationDetails />
      </div>

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p>Period</p>
            </div>
            <div className="th">
              <p>{periodDetails?.stage} start Date</p>
            </div>
            <div className="th">
              <p>{periodDetails?.stage} End Date</p>
            </div>
            <div className="th">
              <p></p>
            </div>
          </div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="td">
              {`${periodDetails?.year} ${periodDetails?.stage}`}
            </div>
            <div className="td">{periodDetails?.startDate}</div>
            <div className="td">{periodDetails?.endDate}</div>
            <div className="td">Active</div>
            <div className="td">
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ overflowX: "auto" }}>
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              <div className="th">
                <p>Targets/Goals/Commitments</p>
              </div>
              <div className="th">
                <p>Weight</p>
              </div>
              <div className="th">
                <p>Sequence</p>
              </div>
              <div className="th">
                <p>Expectation</p>
              </div>
              <div className="th">
                <p>Achievement</p>
              </div>
              <div className="th">
                <p>{periodDetails?.stage} Score (../100)</p>
              </div>
              <div className="th"></div>
            </div>
          </div>
          <div className="table-body">
            {isFetching ? (
              <IsLoading />
            ) : (
              Array.isArray(performance) &&
              performance?.map((data) => (
                <div className="table-row" key={data.id}>
                  <div className="td">{data.objective}</div>
                  <div className="td">{data.weight}</div>
                  <div className="td">{data.priority}</div>
                  <div className="td">
                    <textarea
                      placeholder="Enter Expectation"
                      style={{ padding: "0.5rem" }}
                      name="initialExpectation"
                      id=""
                      cols="50"
                      rows="2"
                      value={getStageExpectation(data?.expectations)}
                      onChange={(e) =>
                        handleChangeInitialExpectation(data, e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div className="td">
                    <textarea
                      placeholder="Enter Achievements"
                      style={{ padding: "0.5rem" }}
                      name="finalExpectation"
                      id=""
                      cols="50"
                      rows="2"
                      defaultValue={
                        getFinalExpectation(data?.expectations) ?? ""
                      }
                      onChange={(e) =>
                        handleChangeFinalExpectation(data, e.target.value)
                      }
                    ></textarea>
                  </div>
                  <div className="td">
                    {data[periodScore(periodDetails?.stage)] ? (
                      data[periodScore(periodDetails?.stage)]
                    ) : (
                      <input
                        defaultValue={null}
                        type="number"
                        style={{ width: "100%", height: "3.2rem" }}
                        max={100}
                        className="table-input"
                        onChange={(e) =>
                          handleChangeScore(data, e.target.value)
                        }
                      />
                    )}
                  </div>
                </div>
              ))
            )}

            <button
              className="add-button pointer"
              type="button"
              onClick={() => submit()}
            >
              <RiAddLine />
              Submit Goals Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuarterlyEntry;
