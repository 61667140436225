import React, { useEffect } from "react";

import { Provider, useDispatch } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactNotifications from "react-notifications-component";
import ProtectedRoute from "./routes/ProtectedRoute";
import UserPortal from "./components/UserPortal";
import Connect from "./components/home/connect";
import { fetchDashboardDetails, fetchRoles } from "./redux/actions/actions";

function App() {
  const history = createBrowserHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboardDetails());
    dispatch(fetchRoles());
  }, []);

  return (
    <Router history={history}>
      <div>
        <ReactNotifications />
        <Switch>
          <Route path="/" exact component={Connect} />

          <ProtectedRoute path="/admin-portal/" component={UserPortal} />
          <Route render={() => <h1>Error 404. Page not found.</h1>} />
        </Switch>
      </div>
    </Router>
  );
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
};

export default AppWrapper;
