
import {
    SUBMIT_SCORE,
    SUBMIT_SCORE_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    submitScore: {},
    isLoading: false,
  };


const submitScoreReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SUBMIT_SCORE:
        return {
          ...state,
          submitScore: action.payload,
        };
  
      case SUBMIT_SCORE_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default submitScoreReducer;