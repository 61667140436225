import { useEffect, useState } from "react";
import { getExpectations } from "../network/services";

export const useGetExpectations = (
  performance,
  mda,
  performanceStage = "Planning",
  isShowExpectationsModal
) => {
  const [isFetchingExpectations, setIsFetchingExpecations] = useState(false);
  const [expectations, setExpectations] = useState([]);

  const handleViewExpectations = async () => {
    setIsFetchingExpecations(true);
    try {
      const response = await getExpectations(performance, mda);

      let _expectations = response?.data?.data;
      let result = _expectations.filter(
        (item) => item?.performanceStage === performanceStage
      );
      setExpectations(result);
    } catch (error) {}
    setIsFetchingExpecations(false);
  };

  useEffect(() => {
    if (isShowExpectationsModal) {
      handleViewExpectations();
    }
  }, [isShowExpectationsModal]);

  return {
    expectations,
    isFetchingExpectations,
  };
};
