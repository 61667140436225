import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import LocationDetails from "../../layouts/LocationDetails";
import DevelopmentNeedsModal from "../../layouts/DevelopmentNeedsModal";
import ReviewGoalsModal from "../../layouts/reviewGoalsModal";
import {
  fetchGoals,
  handleGetDevelopmentNeed,
  handleSubmitSupervisorApproval,
} from "../../../redux/actions/actions";
import {
  getPerformanceStages,
  supervisorDecline,
} from "../../../network/services";
import IsLoading from "../../common/isLoading";
import Spinner from "../../common/spinner/spinner";
import ObjectivesModal from "../../layouts/objectivesModal";
import SupervisorApprovalModal from "../../layouts/supervisorApprovalModal";
import { feedback } from "../../../utils/feedback";
import { useGetExpectations } from "../../../customHooks/useGetExpectations";
import ExpectationsModal from "../../layouts/expectationsModal";
import CustomSearch from "../../common/CustomSearch/customSearch";

import "./quarterlyReview.scss";

const QuarterlyReview = () => {
  const [showDevelopmentNeedsModal, setShowDevelopmentNeedsModal] =
    useState(false);
  const [showReviewGoalsModal, setShowReviewGoalsModal] = useState(false);
  const [reviewGoal, setReviewGoal] = useState({});
  const [developmentNeed, setDevelopmentNeed] = useState({});
  const [stage, setStage] = useState({});
  const [newGoals, setGoals] = useState([]);
  const [goalObjectives, setGoalObjectives] = useState([]);
  const [isShowObjectivesModal, setIsShowObjectivesModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [comments, setComments] = useState("");
  const [expectationParam, setExpectationParam] = useState(null);
  const [query, setQuery] = useState({});
  const [isShowExpectationsModal, setIsShowExpectationsModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getStages();
  }, []);

  useEffect(() => {
    dispatch(fetchGoals(null, query));
  }, [query]);

  const getStages = async () => {
    const res = await getPerformanceStages();

    if (res?.data?.code === 1 || res?.data?.code === "1") {
      setStage(res?.data?.data);
    }
  };

  const goals = useSelector((state) => state?.goals.goals);

  const periodDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const goalsLoader = useSelector((state) => state?.goals.isLoading);

  const handleDevelopmentNeed = (goal) => {
    dispatch(handleGetDevelopmentNeed(goal?.details[0].performanceID));
    setDevelopmentNeed(goal);
    setShowDevelopmentNeedsModal(true);
  };

  const handleReviewGoals = (goal) => {
    setReviewGoal(goal?.details);
    setShowReviewGoalsModal(true);
  };

  const handleViewObjectives = (item) => {
    setGoalObjectives(item);
    setIsShowObjectivesModal(true);
  };

  const submit = (data) => {
    const idee = stage.filter((data) => data?.stages === periodDetails.stage);

    if (idee.length < 1) {
      return feedback({
        title: "Warning",
        text: "Quarterly review has passed the end date.",
        iconType: "warning",
      });
    } else {
      dispatch(
        handleSubmitSupervisorApproval(
          data?.details[0].performanceID,
          idee[0].id,
          { comments }
        )
      );
    }
  };

  const declineReview = async (data) => {
    try {
      const idee = stage.filter((data) => data?.stages === periodDetails.stage);

      if (idee.length < 1) {
        return feedback({
          title: "Warning",
          text: "Quarterly review has passed the end date.",
          iconType: "warning",
        });
      } else {
        const response = await supervisorDecline(
          data?.details[0].performanceID,
          idee[0]?.id,
          { comments }
        );
        if (response?.data?.code === 1) {
          setShowApprovalModal(false);
          feedback({
            title: "Success",
            text: response?.data?.message,
            iconType: "success",
          });
        } else {
          feedback({
            title: "Failed",
            text: response?.data?.message,
            iconType: "error",
          });
        }
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const { expectations, isFetchingExpectations } = useGetExpectations(
    expectationParam?.performanceId,
    expectationParam?.mdaId,
    periodDetails?.stage,
    isShowExpectationsModal
  );

  const handleExpectations = (performanceId, mdaId) => {
    return setExpectationParam({
      performanceId,
      mdaId,
    });
  };

  const submitLoader = useSelector(
    (state) => state?.submitSupervisorApproval.isLoading
  );

  const handleCommentsChange = (e) => {
    const value = e.target.value;

    return setComments(value);
  };

  const handleApprove = (gg) => {
    setShowApprovalModal(true);
    setModalObject({
      actionName: "Approve",
      parameter: gg,
    });
  };

  const handleDecline = (gg) => {
    setShowApprovalModal(true);
    setModalObject({
      actionName: "Decline",
      parameter: gg,
    });
  };

  const onSearch = (value) => {
    return setQuery((prev) => {
      return {
        ...prev,
        mda: value,
      };
    });
  };

  useEffect(() => {
    setGoals(goals);
  }, [goals]);

  return goalsLoader ? (
    <IsLoading />
  ) : (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <div>
          <p>Good Day</p>
          <p>{Cookies.get("fullName")}</p>
          <p>{Cookies.get("_Role")}</p>
        </div>
        <LocationDetails />
      </div>

      <div style={{ marginTop: "1rem" }}>
        <CustomSearch
          name="firstName"
          label="Director's First Name"
          placeholder="Enter First Name"
          type="select"
          required
          onSearch={onSearch}
        />
      </div>

      <div style={{ overflowX: "scroll" }}>
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row ">
              <div className="th">
                <p className="san-sherif">MDA Name</p>
              </div>
              <div className="th">
                <p className="san-sherif">Expectation</p>
              </div>
              <div className="th">
                <p className="san-sherif">Staff name</p>
              </div>
              <div className="th">
                <p className="san-sherif">Objectives</p>
              </div>
              <div className="th">
                <p className="san-sherif">Status</p>
              </div>
              <div className="th">
                <p className="san-sherif">Development Needs</p>
              </div>
              <div className="th">
                <p className="san-sherif">Review Goals / MidYear Score</p>
              </div>
              <div className="th">
                <p className="san-sherif">Approve/Decline</p>
              </div>
            </div>
          </div>
          {submitLoader ? (
            <Spinner />
          ) : (
            <div className="table-body">
              {Array.isArray(newGoals) &&
                newGoals.map((goal) => (
                  <div className="table-row" key={goal.createdBy}>
                    <div className="td ">{goal.details[0]?.mdaName}</div>
                    <div className="td actions">
                      <button
                        type="button"
                        className="btn btn-large w-100 activate"
                        style={{ marginRight: "5px" }}
                        onClick={() => {
                          setIsShowExpectationsModal(true);
                          handleExpectations(
                            goal?.details[0]?.performanceID,
                            goal?.details[0]?.mdaId
                          );
                        }}
                      >
                        View
                      </button>
                    </div>
                    <div className="td">{goal.createdBy}</div>
                    <div className="td ">
                      <button
                        onClick={() => handleViewObjectives(goal)}
                        className="actions-button"
                      >
                        View
                      </button>
                    </div>
                    <div className="td"> APPROVER</div>
                    <div className="td ">
                      <button
                        onClick={() => handleDevelopmentNeed(goal)}
                        className="actions-button"
                      >
                        Add/View
                      </button>
                    </div>
                    <div className="td ">
                      <button
                        onClick={() => handleReviewGoals(goal)}
                        className="actions-button"
                      >
                        Edit Score
                      </button>
                    </div>

                    <div className="td actions ">
                      <button
                        className="activate"
                        onClick={() => handleApprove(goal)}
                      >
                        Approve
                      </button>
                      <button
                        className="deactivate m-l-5"
                        onClick={() => handleDecline(goal)}
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>

      {showDevelopmentNeedsModal && (
        <DevelopmentNeedsModal
          goal={reviewGoal}
          needData={developmentNeed}
          closeModal={() => setShowDevelopmentNeedsModal(false)}
        />
      )}
      {showApprovalModal && (
        <SupervisorApprovalModal
          closeModal={() => setShowApprovalModal(false)}
          actionName={modalObject?.actionName}
          handleChange={handleCommentsChange}
          handleAction={
            modalObject?.actionName === "Approve"
              ? () => submit(modalObject?.parameter)
              : () => declineReview(modalObject?.parameter)
          }
        />
      )}

      {showReviewGoalsModal && (
        <ReviewGoalsModal
          goal={reviewGoal}
          setReviewGoal={setReviewGoal}
          showReviewGoalsModal={showReviewGoalsModal}
          closeModal={() => setShowReviewGoalsModal(false)}
        />
      )}

      {isShowObjectivesModal && (
        <ObjectivesModal
          data={goalObjectives}
          closeModal={() => setIsShowObjectivesModal(false)}
        />
      )}

      {isShowExpectationsModal && (
        <ExpectationsModal
          closeModal={() => setIsShowExpectationsModal(false)}
          expectations={expectations}
          isFetchingExpectations={isFetchingExpectations}
        />
      )}
    </div>
  );
};

export default QuarterlyReview;
