import React from "react";

import { IoMdClose } from "react-icons/io";

const ObjectivesModal = ({ data, closeModal }) => {
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={() => closeModal()} />
      <div className="modal-box w-100 ">
        <div className="content cloak">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">Id</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Objectives</p>
                </div>
              </div>
            </div>
            <div className="table-body">
              {data?.details.map((item) => (
                <div className="table-row" key={item.id}>
                  <div className="td">{item?.id}</div>
                  <div className="td">{item?.objective}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ObjectivesModal;
