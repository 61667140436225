import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import TextInput from "../inputs/TextInput";
import {
  deleteCompetencyCategory,
  postCompetencyCategory,
} from "../../network/services";
import { feedback } from "../../utils/feedback";
import IsLoading from "../common/isLoading";
import { fetchCompetencyCategory } from "../../redux/actions/actions";

const CreateCompetencyCategoryForm = ({ closeModal }) => {
  const [inputData, setInputData] = useState();
  const [selectedRecord, setSelectedRecord] = useState({});

  const dispatch = useDispatch();

  const competencyCategory = useSelector(
    (state) => state?.competencyReducer.competencyCategory
  );

  const isLoading = useSelector((state) => state?.competencyReducer.isLoading);

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleDelete = async (id) => {
    try {
      const response = await deleteCompetencyCategory(id);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        dispatch(fetchCompetencyCategory());
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleSave = async (data) => {
    const payload = {
      categoryName: data?.categoryName,
    };
    try {
      const response = await postCompetencyCategory(payload);
      if (response?.status === 200) {
        dispatch(fetchCompetencyCategory());
      }
    } catch (error) {}
  };

  const handleEdit = (data) => {
    setSelectedRecord(data);
  };

  useEffect(() => {
    dispatch(fetchCompetencyCategory());
  }, []);

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box" style={{ width: "50%" }}>
        <div className="content">
          <TextInput
            className="w-100 m-b-10"
            name="categoryName"
            label="Category"
            type="text"
            onChange={handleChange}
            defaultValue={selectedRecord && selectedRecord?.categoryName}
          />

          <button
            type="button"
            className="btn"
            onClick={() => handleSave(inputData)}
          >
            Save Record
          </button>

          {isLoading ? (
            <IsLoading />
          ) : (
            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p className="san-sherif">Category</p>
                  </div>

                  <div className="th">
                    <p className="san-sherif">Last Updated By</p>
                  </div>
                  <div className="th"></div>
                </div>
              </div>
              <div className="table-body">
                {competencyCategory.map((item) => (
                  <div className="table-row" key={item.id}>
                    <div className="td">{item?.categoryName}</div>
                    <div className="td">
                      {item?.modifiedBy} {item?.date}
                    </div>
                    <div className="td actions">
                      <button
                        type="button"
                        className="pointer"
                        onClick={() => handleEdit(item)}
                      >
                        <BiEditAlt />
                      </button>
                      <button
                        type="button"
                        className="del pointer"
                        onClick={() =>
                          feedback(
                            {
                              title: "Competency Settings",
                              text: "Are you sure? Please note that competencies attached to this category will be deleted also",
                              isDialog: true,
                              showCancelButton: true,
                              confirmButtonText: "Delete",
                              iconType: "warning",
                            },
                            () => handleDelete(item.id)
                          )
                        }
                      >
                        <GiTrashCan />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateCompetencyCategoryForm;
