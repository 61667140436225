import {
 
 SUBMIT_DEVELOPMENT_NEEDS ,
 UPDATE_DEVELOPMENT_NEEDS ,

 DELETE_DEVELOPMENT_NEEDS ,
 GET_DEVELOPMENT_NEEDS ,
 DEVELOPMENT_NEEDS_LOADER ,
 
} from "../actions/actionsTypes";

 
  const INITIAL_STATE = {
    submitDevelopmentNeeds: {},
    isLoading: false,
  };
  const UPDATE_INITIAL_STATE = {
    updateDevelopmentNeeds: {},
    isLoading: false,
  };
  const DELETE_INITIAL_STATE = {
    deleteDevelopmentNeeds: {},
    isLoading: false,
  };
  const GET_INITIAL_STATE = {
    getDevelopmentNeeds: {},
    isLoading: false,
  };



export const SubmitDevelopmentNeedsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SUBMIT_DEVELOPMENT_NEEDS:
        return {
          ...state,
          submitDevelopmentNeeds: action.payload,
        };
  
      case DEVELOPMENT_NEEDS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  



  export const UpdateDevelopmentNeedsReducer = (state = UPDATE_INITIAL_STATE, action) => {
    switch (action.type) {
      case UPDATE_DEVELOPMENT_NEEDS:
        return {
          ...state,
          updateDevelopmentNeeds: action.payload,
        };
  
      case DEVELOPMENT_NEEDS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
 
  
  export const DeleteDevelopmentNeedsReducer = (state = DELETE_INITIAL_STATE, action) => {
    switch (action.type) {
      case DELETE_DEVELOPMENT_NEEDS:
        return {
          ...state,
          deleteDevelopmentNeeds: action.payload,
        };
  
      case DEVELOPMENT_NEEDS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };

  export const GetDevelopmentNeedsReducer = (state = GET_INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_DEVELOPMENT_NEEDS:
        return {
          ...state,
          getDevelopmentNeeds: action.payload,
        };
  
      case DEVELOPMENT_NEEDS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
