import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";

import TextInput from "../inputs/TextInput";

const AppraisalRatingsForm = ({ closeModal, handleSave }) => {
  const [inputData, setInputData] = useState();

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            className="w-100 m-b-10"
            name="grade"
            label="Grade"
            type="text"
            onChange={handleChange}
            placeholder="e.g. (A-F)"
          />
          <TextInput
            className="w-100 m-b-10"
            name="fromScore"
            label="From Score"
            type="text"
            onChange={handleChange}
          />
          <TextInput
            className="w-100 m-b-10"
            name="toScore"
            label="To Score"
            type="text"
            onChange={handleChange}
          />
          <TextInput
            className="w-100 m-b-10"
            name="ratingDescription"
            label="Description"
            type="text"
            onChange={handleChange}
          />

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={() => handleSave(inputData)}
          >
            Save Record
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppraisalRatingsForm;
