/**
 *
 * @param {JSON data} jsonData
 * @param {data-key: value-to-store-key-as} supportedKeys
 * @returns Object
 */
import moment from "moment";

function sortObjectByKeys(o, order) {
  return Object.keys(o)
    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
    .reduce((r, k) => ((r[k] = o[k]), r), {});
}

export const prepareForExport = (jsonData, supportedKeys = {}) => {
  const result = [];
  jsonData.forEach((unsorted) => {
    const row = sortObjectByKeys(unsorted, Object.keys(supportedKeys));
    const singleRow = {};
    Object.keys(row).map((key) => {
      if (!supportedKeys[key]) return;
      else if (typeof supportedKeys[key] === "object" && row[key]) {
        const extractedContent = prepareForExport(
          [row[key]],
          supportedKeys[key]
        );
        Object.assign(singleRow, extractedContent[0]);
      } else if (supportedKeys[key]) {
        singleRow[supportedKeys[key]] = cleanValue(key, row[key]);
      }
    });
    result.push(singleRow);
  });
  return result;
};

export const prepareForExportUsingTableColumn = (
  jsonData = [],
  tableColumn = []
) => {
  const result = [];
  jsonData.forEach((data) => {
    const singleRow = {};
    tableColumn.forEach((column, index) => {
      if (column.dataIndex) singleRow[column.title] = data[column.dataIndex];
      if (column.render) {
        const value = column.render(data, index, index).props.children;
        if (Array.isArray(value)) singleRow[column.title] = value.join("");
        else singleRow[column.title] = value;
      }
    });
    result.push(singleRow);
  });
  return result;
};

const cleanValue = (key, value) => {
  if (key === "dateCreated") return moment(value).format("YYYY-MM-DD");
  if (typeof value === "boolean" && value === true) return "Yes";
  if (typeof value === "boolean" && value === false) return "No";
  return value;
  // switch (key) {
  //   // case 'lga':
  //   //   return value.constructor.name !== 'String'
  //   //     ? value.lgaName
  //   //     : JSON.parse(value).lgaName;
  //   // case 'country':
  //   //   return value.constructor.name !== 'String'
  //   //     ? value.name
  //   //     : JSON.parse(value).name;
  //   // case 'state':
  //   //   return value.constructor.name !== 'String'
  //   //     ? value.stateName
  //   //     : JSON.parse(value).stateName;
  //   // case 'mutualFund':
  //   //   return value.constructor.name !== 'String'
  //   //     ? value.title
  //   //     : JSON.parse(value).title;
  //   // case 'fundManager':
  //   //   return value.constructor.name !== 'String'
  //   //     ? value.name
  //   //     : JSON.parse(value).name;
  //   case 'dateCreated':
  //     return moment(value).format('YYYY-MM-DD');
  //   default:

  // }
};
