import {
    GET_GOALS,
    SET_GOALS_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    goals: {},
    isLoading: false,
  };


const GoalsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_GOALS:
        return {
          ...state,
          goals: action.payload,
        };
  
      case SET_GOALS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default GoalsReducer;