import { useState, useEffect } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { AiOutlinePrinter } from "react-icons/ai";
import LocationDetails from "../../layouts/LocationDetails";
import {
  getApprovedList,
  getPerformanceStages,
} from "../../../network/services";
import IsLoading from "../../common/isLoading";
import { generatePDF } from "../../../utility/exportPDF";
import { prepareForExport } from "../../../utility/convertDataForExport";
import CustomSearch from "../../common/CustomSearch/customSearch";
import { feedback } from "../../../utils/feedback";

const ApprovedList = () => {
  const periodDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const [stages, setStages] = useState([]);
  const [approvedList, setApprovedList] = useState([]);
  const [isFetchingApprovalList, setIsFetchingApprovalList] = useState(false);
  const [page] = useState(0);
  const [limit] = useState(50);
  const [query, setQuery] = useState({
    stageId: periodDetails?.performaceStageId,
  });

  const SUPPORTED_KEYS = {
    serialNo: "S/N",
    mdaName: "MDA",
    createdBy: "Created By",
    objective: "Objective",
    performanceIndicator: "KPI",
    comments: "Comments",
    score: "Score",
    supervisorComments: "Supervisor Comments",
    supervisorScore: "Supervisor Score",
    approvalStatus: "Approval Status",
  };

  const getStages = async () => {
    const res = await getPerformanceStages();
    if (res?.data?.code === 1 || res?.data?.code === "1") {
      setStages(res?.data?.data);
    }
  };

  useEffect(() => {
    getStages();
  }, []);

  const fetchApprovedList = async (id, page, limit, mdaName) => {
    setIsFetchingApprovalList(true);
    try {
      const response = await getApprovedList(id, page, limit, mdaName);
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        let result = response?.data?.data;
        let list = [];

        for (let i = 0; i < result.length; i++) {
          for (let j = 0; j < result[i]?.details?.length; j++) {
            list.push(result[i]?.details[j]);
          }
        }

        const _approvalList = list.map((listItem, index) => {
          if (periodDetails?.stage === "Quarter 3 Appraisal") {
            return {
              ...listItem,
              serialNo: index + 1,
              score: listItem?.quaterThreeScore,
              comments: listItem?.quaterThreeComment,
              supervisorComments: listItem?.quaterThreeSupervisorComment,
              supervisorScore: listItem?.quaterThreeSupervisorScore,
            };
          } else if (periodDetails?.stage === "Quarter 2 Appraisal") {
            return {
              ...listItem,
              serialNo: index + 1,
              score: listItem?.quaterTwoScore,
              comments: listItem?.quaterTwoComment,
              supervisorComments: listItem?.quaterTwoSupervisorComment,
              supervisorScore: listItem?.quaterTwoSupervisorScore,
            };
          } else if (periodDetails?.stage === "Quarter 1 Appraisal") {
            return {
              ...listItem,
              serialNo: index + 1,
              score: listItem?.quaterOneScore,
              comments: listItem?.quaterOneComment,
              supervisorComments: listItem?.quaterOneSupervisorComment,
              supervisorScore: listItem?.quaterOneSupervisorScore,
            };
          } else if (periodDetails?.stage === "End of Year Appraisal") {
            return {
              ...listItem,
              serialNo: index + 1,
              score: listItem?.quaterFourScore,
              comments: listItem?.quaterFourComment,
              supervisorComments: listItem?.quaterFourSupervisorComment,
              supervisorScore: listItem?.quaterFourSupervisorScore,
            };
          } else {
            return {
              ...listItem,
              serialNo: index + 1,
            };
          }
        });

        setApprovedList(_approvalList);
      } else {
        feedback({
          title: "Failure",
          text:
            response?.data?.message ??
            "An error occured while fetching treated appraisals",
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failure",
        text:
          error?.response?.data?.message ??
          "An error occured while fetching treated appraisals",
        iconType: "error",
      });
    }
    setIsFetchingApprovalList(false);
  };

  useEffect(() => {
    fetchApprovedList(query?.stageId, page, limit, query?.mda);
  }, [query]);

  const generateFileName = (fileName, ext) => {
    const date = new Date();
    return `${fileName}_${date.toLocaleString().replace(" ", "_")}.${ext}`;
  };

  const pdfConfig = {
    orientation: "landscape",
  };

  const handleExport = (format = "PRINT") => {
    generatePDF(
      generateFileName("approval_list", "pdf"),
      prepareForExport(approvedList, SUPPORTED_KEYS),
      format === "PRINT",
      null,
      pdfConfig
    );
  };

  const onSearch = (value) => {
    return setQuery((prev) => {
      return {
        ...prev,
        mda: value,
      };
    });
  };

  const handleChangeStage = (e) => {
    const value = e.target.value;

    return setQuery((prev) => {
      return {
        ...prev,
        stageId: value,
      };
    });
  };

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <div>
          <p>Good Day</p>
          <p>{Cookies.get("fullName")}</p>
          <p>{Cookies.get("_Role")}</p>
        </div>
        <LocationDetails />
      </div>

      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <CustomSearch
            placeholder="Search By MDA"
            type="select"
            required
            onSearch={onSearch}
          />
          <select
            style={{ height: "2.1rem", marginLeft: "0.5rem", border: "none" }}
            onChange={handleChangeStage}
          >
            <option value="">Select Stage</option>
            {stages.map((stage, index) => (
              <option key={index} value={stage?.id}>
                {stage?.stages}
              </option>
            ))}
          </select>
        </div>

        <button
          className="add-button pointer"
          type="button"
          onClick={handleExport}
        >
          <AiOutlinePrinter />
          Export to PDF
        </button>
      </div>

      <div style={{ overflowX: "scroll" }}>
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row ">
              <div className="th">
                <p className="san-sherif">S/N</p>
              </div>
              <div className="th">
                <p className="san-sherif">MDA</p>
              </div>
              <div className="th">
                <p className="san-sherif">Created By</p>
              </div>
              <div className="th">
                <p className="san-sherif">Objective</p>
              </div>
              <div className="th">
                <p className="san-sherif">KPI</p>
              </div>
              {periodDetails?.stage !== "Planning" && (
                <>
                  <div className="th">
                    <p className="san-sherif">Comments </p>
                  </div>
                  <div className="th">
                    <p className="san-sherif">Score </p>
                  </div>
                  <div className="th">
                    <p className="san-sherif">Supervisor Comments </p>
                  </div>
                  <div className="th">
                    <p className="san-sherif">Supervisor Score </p>
                  </div>
                </>
              )}
              <div className="th">
                <p className="san-sherif">Approval Status</p>
              </div>
            </div>
          </div>
          {isFetchingApprovalList ? (
            <IsLoading />
          ) : (
            <div className="table-body">
              {approvedList.map((item, index) => (
                <div className="table-row" key={index}>
                  <div className="td">{item?.serialNo}</div>
                  <div className="td">{item?.mdaName}</div>
                  <div className="td">{item?.createdByName}</div>
                  <div className="td">{item?.objective}</div>
                  <div className="td">{item?.performanceIndicator}</div>
                  {periodDetails?.stage !== "Planning" && (
                    <>
                      <div className="td">{item?.comments}</div>
                      <div className="td">{item?.score}</div>
                      <div className="td">{item?.supervisorComments}</div>
                      <div className="td">{item?.supervisorScore}</div>
                    </>
                  )}
                  <div className="td">
                    <span
                      style={{
                        display: "inline-block",
                        padding: "0.5rem",
                        background: "orange",
                        borderRadius: "5px",
                        color: "#ffffff",
                        fontWeight: "bolder",
                      }}
                    >
                      {item?.approvalStatus}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApprovedList;
