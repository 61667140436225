import { IoMdClose } from "react-icons/io";

import TextareaInput from "../inputs/TextareaInput";

const SupervisorApprovalModal = ({
  closeModal,
  handleAction,
  actionName,
  handleChange,
}) => {
  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-800">
        <div className="content cloak">
          <TextareaInput
            className="w-100 m-b-10"
            label="Comments: "
            onChange={handleChange}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <button
              type="button"
              className="btn btn-large"
              onClick={handleAction}
            >
              {actionName}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupervisorApprovalModal;
