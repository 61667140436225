import Http from "./index";

export const getPerformanceStages = () => {
  return Http.get("/api/PerformanceStage");
};

export const putPerformanceStages = (data) => {
  return Http.put("/api/PerformanceStage", data);
};

export const getDashboardDetails = () => {
  return Http.get("/api/Dashboard/details/1");
};

export const getApprovalSetup = () => {
  return Http.get("/approvalsetup");
};

export const getRoles = () => {
  return Http.get("/roles");
};

export const getStaffByRoles = (roleId) => {
  return Http.get(`/staffbyroles/${roleId}`);
};

export const getAppraisalRatings = () => {
  return Http.get("/api/AppraisalRatingSetup");
};

export const postAppraisalRatings = (data) => {
  return Http.post("/api/AppraisalRatingSetup", data);
};

export const delAppraisalRatings = (id) => {
  return Http.delete(`/api/AppraisalRatingSetup?id=${id}`);
};

export const putAppraisalRatings = (data) => {
  return Http.put("/api/AppraisalRatingSetup", data);
};

export const getCompetencyCategory = () => {
  return Http.get("/api/Competency/category");
};

export const postCompetencyCategory = (data) => {
  return Http.post("/api/Competency/category", data);
};

export const deleteCompetencyCategory = (id) => {
  return Http.delete(`/api/Competency/category?id=${id}`);
};

export const postCompetency = (data) => {
  return Http.post(`/api/Competency/competency`, data);
};

export const putCompetency = (data) => {
  return Http.put(`/api/Competency/competency`, data);
};

export const delCompetency = (id) => {
  return Http.delete(`/api/Competency/competency?id=${id}`);
};

export const getCompetencyByCategoryId = (categoryId) => {
  return Http.get(`/api/Competency/competency/bycategory/${categoryId}`);
};

export const getPerformanceHeader = (periodId) => {
  return Http.get(`/api/PerformanceGoalDetail/performanceheader/${periodId}`);
};

export const postInitiatePerformance = (periodId) => {
  return Http.post(`api/PerformanceGoalDetail/initiateperformance/${periodId}`);
};

export const getPerformanceGoalsData = (id) => {
  return Http.get(`/api/PerformanceGoalDetail/goals/${id}`);
};

export const postPerformnaceGoal = (data) => {
  return Http.post(`/api/PerformanceGoalDetail/setgoal`, data);
};

export const postInitiatePerformanceApproval = (data) => {
  return Http.post(
    `/api/PerformanceGoalDetail/initiateperformanceapproval/${data}`
  );
};

export const getPerformanceDetailApprovalProgress = (id) => {
  return Http.get(`/api/PerformanceGoalDetail/approvalprogress/${id}`);
};

export const getCurrentPeriod = () => {
  return Http.get(`/api/AppraisalPeriod/currentperiod`);
};

export const getPerformanceDetailApprovalStafflist = (currentPeriodId) => {
  return Http.get(
    `/api/PerformanceGoalDetail/approvalstafflist/${currentPeriodId}`
  );
};

export const getReviewGoals = (id) => {
  return Http.get(`/api/PerformanceGoalDetail/goalsdata/${id}`);
};

export const postApprovePerformance = (data) => {
  return Http.put(`/api/PerformanceGoalDetail/approveperformance`, data);
};
export const getPerformanceGoalDetailsWithData = (data) => {
  return Http.get(`/api/PerformanceGoalDetail/goals/${data}`);
};

export const getPerformanceGoalDetails = () => {
  return Http.get(`/api/PerformanceGoalDetail/goals`);
};

export const getMyPerformanceApprovalRoute = () => {
  return Http.get("/api/PerformanceGoalDetail/myapprovalroute");
};

export const getPerformanceApprovalProgress = () => {
  return Http.get(
    "/api/PerformanceGoalDetail/approvalprogress/{performanceid}"
  );
};

export const approvePerformance = () => {
  return Http.get(
    "​/api​/PerformanceGoalDetail​/initiateperformanceapproval​/"
  );
};

export const getQuarterPerformance = () => {
  return Http.get("/api/MidYear/performance/{performanceid}");
};

export const getGoals = (query) => {
  return Http.get(`/api/PerformanceGoalDetail/supervisor/goals`);
};

export const submitScore = (data) => {
  return Http.put("/api/MidYear/updatescore", data);
};

export const submitSupervisorScore = (data) => {
  return Http.put("/api/MidYear/updatescore/supervisor", data);
};

export const submit = (data) => {
  return Http.put(`/api/MidYear/submitforapproval/${data}`);
};

export const submitSupervisor = (data, stage, body) => {
  return Http.put(`/api/MidYear/supervisorapproval/${data}/${stage}`, body);
};

export const supervisorDecline = (performanceid, stage, body) => {
  return Http.put(
    `/api/MidYear/supervisor/decline/${performanceid}/${stage}`,
    body
  );
};

export const submitDevelopmentNeed = (data) => {
  return Http.post(`/api/MidYear/developmentneed`, data);
};

export const updateDevelopmentNeed = (data) => {
  return Http.put(`/api/MidYear/developmentneed`, data);
};

export const deleteDevelopmentNeed = (data) => {
  return Http.delete(`/api/MidYear/developmentneed/${data}`);
};

export const getDevelopmentNeed = (data) => {
  return Http.get(`/api/MidYear/developmentneed/${data}`);
};

export const getExpectations = (performanceId, mdaId) => {
  return Http.get(
    `/api/PerformanceGoalDetail/expectations/${performanceId}/mdaid?mdaid=${mdaId}`
  );
};

export const getApprovedList = (stageId, page, limit, mda) => {
  return Http.get(
    `/api/PerformanceGoalDetail/supervisor/goals/treated/${stageId}?page=${page}&limit=${limit}&mda=${mda}`
  );
};

export const putSupervisorEditGoals = (data) => {
  return Http.put(`/api/PerformanceGoalDetail/supervisor/updategoal`, data);
};
