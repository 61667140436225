import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import LocationDetails from "../../../layouts/LocationDetails";
import ProfileForm from "../../../layouts/ProfileForm";
import {
  getPerformanceStages,
  putPerformanceStages,
} from "../../../../network/services";
import IsLoading from "../../../common/isLoading";
import { feedback } from "../../../../utils/feedback";
import moment from "moment";
import PeriodForm from "../../../layouts/activatePeriodForm";
import { fetchDashboardDetails } from "../../../../redux/actions/actions";

const AppraisalPeriod = ({ history, details = {} }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showPeriodFormModal, setShowPeriodFormModal] = useState(false);
  const [performanceStages, setPerformanceStages] = useState([]);
  const [activationData, setActivationData] = useState({});
  const [isFetchingPerformanceStages, setIsFetchingPerformanceStages] =
    useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Employee Profile";
  }, []);

  const dispatch = useDispatch();

  const headerDetails = useSelector(
    (state) => state.dashboard.dashboardDetails
  );

  const handleDeactivate = async (data) => {
    const payload = {
      id: data?.id,
      periodID: data?.periodID,
      status: 0,
      startDate: moment(data?.startDate).format(),
      endDate: moment(data?.endDate).format(),
    };

    try {
      const response = await putPerformanceStages(payload);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchPerformanceStages();
        dispatch(fetchDashboardDetails());
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleActivate = (data) => {
    setActivationData(data);
    setShowPeriodFormModal(true);
  };

  const Activate = async (data) => {
    const payload = {
      id: activationData?.id,
      periodID: activationData?.periodID,
      status: 1,
      startDate: data?.startDate,
      endDate: data?.endDate,
    };

    try {
      const response = await putPerformanceStages(payload);
      if (response?.status === 200) {
        setShowPeriodFormModal(false);
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchPerformanceStages();
        dispatch(fetchDashboardDetails());
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const fetchPerformanceStages = async () => {
    setIsFetchingPerformanceStages(true);
    try {
      const response = await getPerformanceStages();
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        setPerformanceStages(response?.data?.data);
      } else {
        alert("Could not retrieve performance stages");
      }
    } catch (error) {
      alert("Could not retrieve performance stages");
    }
    setIsFetchingPerformanceStages(false);
  };

  useEffect(() => {
    fetchPerformanceStages();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Setup Period</h2>
        <LocationDetails />
      </div>

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p className="san-sherif">Year</p>
            </div>
            <div className="th">
              <p className="san-sherif">Start Date</p>
            </div>
            <div className="th">
              <p className="san-sherif">End Date</p>
            </div>
            {/* <div className="th">
              <p className="san-sherif">Last Updated By</p>
            </div> */}
            {/* <div className="th">
              <button
                className="add-button pointer"
                type="button"
                onClick={() => setShowFormModal(true)}
              >
                <RiAddLine />
                Add Profile
              </button>
            </div> */}
          </div>
        </div>
        <div className="table-body">
          <div className="table-row">
            <div className="td">{headerDetails.year}</div>
            <div className="td">{headerDetails.startDate}</div>
            <div className="td">{headerDetails.endDate}</div>
            {/* <div className="td">EdoGov Admin 06-Jan-2022 12:31:45 PM</div> */}
            {/* <div className="td actions">
                <button
                  type="button"
                  className="pointer"
                  onClick={() => setShowFormModal(true)}
                >
                  <BiEditAlt />
                </button>
                <button type="button" className="pointer power">
                  <AiOutlinePoweroff />
                </button>
                <button type="button" className="del pointer">
                  <GiTrashCan />
                </button>
              </div> */}
          </div>
        </div>
      </div>

      {isFetchingPerformanceStages ? (
        <IsLoading />
      ) : (
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              <div className="th">
                <p className="san-sherif">Performance Stage</p>
              </div>
              <div className="th">
                <p className="san-sherif">Start Date</p>
              </div>
              <div className="th">
                <p className="san-sherif">End Date</p>
              </div>
              <div className="th">
                <p className="san-sherif">Status</p>
              </div>
              {/* <div className="th">
                <p className="san-sherif">Last Updated By</p>
              </div> */}
              <div className="th"></div>
            </div>
          </div>
          <div className="table-body">
            {performanceStages.map((item) => (
              <div className="table-row" key={item.id}>
                <div className="td">{item?.stages}</div>
                <div className="td">{item?.startDate}</div>
                <div className="td">{item?.endDate}</div>
                <div className="td">
                  {item?.status === 1 ? "Active" : "InActive"}
                </div>
                {/* <div className="td">{`${item?.modifiedBy}${item?.date}`}</div> */}
                <div className="td actions">
                  {item?.status === 1 && (
                    <button
                      type="button"
                      className="btn btn-large w-100 deactivate"
                      onClick={() =>
                        feedback(
                          {
                            title: "Period Settings",
                            text: "Click on DEACTIVATE to continue with the selected performance stage deactivation",
                            isDialog: true,
                            showCancelButton: true,
                            confirmButtonText: "Deactivate",
                            iconType: "warning",
                          },
                          () => handleDeactivate(item)
                        )
                      }
                    >
                      Deactivate
                    </button>
                  )}

                  {item?.status === 0 && (
                    <button
                      type="button"
                      className="btn btn-large w-100 activate"
                      onClick={() => handleActivate(item)}
                    >
                      Activate
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {showFormModal && (
        <ProfileForm closeModal={() => setShowFormModal(false)} />
      )}
      {showPeriodFormModal && (
        <PeriodForm
          closeModal={() => setShowPeriodFormModal(false)}
          handleActivate={Activate}
        />
      )}
    </div>
  );
};

export default AppraisalPeriod;
