// Dashboard Details
export const GET_DASHBOARD_DETAILS = "GET_DASHBOARD_DETAILS";
export const SET_DASHBOARD_DETAILS_LOADER = "SET_DASHBOARD_DETAILS_LOADER";

// Roles
export const GET_ROLES = "GET_ROLES";

//Competency
export const GET_COMPETENCY_CATEGORY = "GET_COMPETENCY_CATEGORY";
export const GET_COMPETENCY_CATEGORY_LOADER = "GET_COMPETENCY_CATEGORY_LOADER";
export const GET_PERFORMANCE_DETAILS = "GET_PERFORMANCE_DETAILS";
export const SET_PERFORMANCE_DETAILS_LOADER = "SET_PERFORMANCE_DETAILS_LOADER";
export const SET_GOALS_LOADER = "SET_GOALS_LOADER";
export const GET_GOALS = "GET_GOALS";
export const SUBMIT_SCORE = "SUBMIT_SCORE";
export const SUBMIT_SUPERVISOR_SCORE = "SUBMIT_SUPERVISOR_SCORE";
export const SUBMIT_SCORE_LOADER = "SUBMIT_SCORE_LOADER";
export const SUBMIT_SUPERVISOR_SCORE_LOADER = "SUBMIT_SUPERVISOR_SCORE_LOADER";
export const SUBMIT_APPROVAL = "SUBMIT_APPROVAL";
export const SUBMIT_APPROVAL_LOADER = "SUBMIT_APPROVAL_LOADER";
export const SUBMIT_APPROVAL_SUPERVISOR = "SUBMIT_APPROVAL_SUPERVISOR";
export const SUBMIT_APPROVAL_SUPERVISOR_LOADER = "SUBMIT_APPROVAL_SUPERVISOR_LOADER";
export const SUBMIT_DEVELOPMENT_NEEDS_LOADER = "SUBMIT_DEVELOPMENT_NEEDS_LOADER";
export const SUBMIT_DEVELOPMENT_NEEDS = "SUBMIT_DEVELOPMENT_NEEDS";
export const UPDATE_DEVELOPMENT_NEEDS = "SUBMIT_DEVELOPMENT_NEEDS";
export const UPDATE_DEVELOPMENT_NEEDS_LOADER = "SUBMIT_DEVELOPMENT_NEEDS_LOADER";
export const DELETE_DEVELOPMENT_NEEDS = "SUBMIT_DEVELOPMENT_NEEDS";
export const DEVELOPMENT_NEEDS_LOADER = "SUBMIT_DEVELOPMENT_NEEDS_LOADER";
export const GET_DEVELOPMENT_NEEDS_LOADER = "GET_DEVELOPMENT_NEEDS_LOADER";
export const GET_DEVELOPMENT_NEEDS = "GET_DEVELOPMENT_NEEDS";
export const GET_MY_APPROVAL_ROUTE = "GET_MY_APPROVAL_ROUTE";
export const GET_MY_APPROVAL_ROUTE_LOADER = "GET_MY_APPROVAL_ROUTE_LOADER";