import { IoMdClose } from "react-icons/io";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import TextInput from "../inputs/TextInput";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleDeleteDevelopmentNeed,
  handleSubmitDevelopmentNeed,
  handleUpdateDevelopmentNeed,
} from "../../redux/actions/actions";
import Spinner from "../common/spinner/spinner";

const DevelopmentNeed = ({ closeModal, needData }) => {
  const [developmentNeeds, setDevelopmentNeed] = useState("");
  const [activities, setActivity] = useState("");
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(0);

  const dispatch = useDispatch();

  const submitForm = () => {
    if (edit === false) {
      dispatch(
        handleSubmitDevelopmentNeed({
          developmentNeeds,
          activities,
          performanceID: needData?.details[0]?.performanceID,
        })
      );
    } else {
      dispatch(
        handleUpdateDevelopmentNeed({
          id,
          developmentNeeds,
          activities,
          performanceID: needData?.details[0]?.performanceID,
        })
      );
    }

    clear();
  };

  const clear = () => {
    setActivity("");
    setDevelopmentNeed("");
    setId(0);
    setEdit(false);
  };

  const handleEdit = (data) => {
    setEdit(true);
    setActivity(data.activities);
    setDevelopmentNeed(data.developmentNeeds);
    setId(data.id);
  };

  const devNeed = useSelector(
    (state) => state?.GetDevelopmentNeedsReducer?.getDevelopmentNeeds
  );
  const devNeedLoader = useSelector(
    (state) => state?.GetDevelopmentNeedsReducer?.isLoading
  );

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-800">
        <div className="content cloak">
          <div className="w-100 modal-border p-20 white-background">
            <div className="m-b-10 w-100 ">
              <TextInput
                name="Development Needs"
                height="50px"
                label="Development Needs"
                value={developmentNeeds}
                className="m-b-10"
                onChange={(e) => setDevelopmentNeed(e.target.value)}
              />
              <TextInput
                name="Activities"
                label="Activities"
                value={activities}
                onChange={(e) => setActivity(e.target.value)}
              />
            </div>

            <button
              type="button"
              className="btn btn-large "
              onClick={submitForm}
            >
              Save Record
            </button>
          </div>

          <div className="table-view m-t-10 ">
            <div className="table-header">
              <div className="table-row ">
                <div className="th">
                  <p>Development Need</p>
                </div>
                <div className="th">
                  <p>Activities</p>
                </div>
                <div className="th">
                  <p></p>
                </div>
              </div>
            </div>
            {devNeedLoader ? (
              <Spinner />
            ) : (
              <div className="table-body">
                {Array.isArray(devNeed) &&
                  devNeed.length > 0 &&
                  devNeed.map((item) => (
                    <div className="table-row white-background">
                      <div className="td">{item.developmentNeeds}</div>
                      <div className="td"> {item.activities}</div>
                      <div className="td action">
                        <button
                          onClick={() => {
                            handleEdit(item);
                          }}
                          className="icon-edit"
                        >
                          <BiEditAlt className="icon" />
                        </button>
                        <button
                          onClick={() => {
                            dispatch(handleDeleteDevelopmentNeed(item.id));
                          }}
                          className="icon-delete m-l-10"
                        >
                          <GiTrashCan className="icon" />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentNeed;
