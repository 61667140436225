import {
  GET_DASHBOARD_DETAILS,
  SET_DASHBOARD_DETAILS_LOADER,
} from "../actions/actionsTypes";

const INITIAL_STATE = {
  dashboardDetails: {},
  isLoading: false,
};

const dashboardDetailsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DETAILS:
      return {
        ...state,
        dashboardDetails: action.payload,
      };

    case SET_DASHBOARD_DETAILS_LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    default:
      return state;
  }
};

export default dashboardDetailsReducer;
