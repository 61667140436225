import React, { useEffect, useState } from "react";

import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import EdsgLogo from "../../assets/images/edsg-logo-250.png";
import { feedback } from "../../utils/feedback";
import { RiBarChartFill } from "react-icons/ri";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { performanceStages } from "../../config/util";

const Sidebar = ({ history, menuList }) => {
  const [supervisorStatus, setSupervisorStatus] = useState(false);
  const [isShowingSub, setIsShowingSub] = useState(false);

  const {
    location: { pathname },
  } = history;

  const stage = useSelector(
    (state) => state?.dashboard?.dashboardDetails?.stage
  );

  if (stage === "") {
    feedback({
      title: "Failed",
      text: "No performance stage is currently activated or the time for the current stage has elapsed. Kindly activate a stage",
      iconType: "warning",
    });
  }

  const getSupervisorStatus = () => {
    if (
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor"))
    ) {
      setSupervisorStatus(JSON.parse(Cookies.get("isSupervisor")));
    } else if (
      Cookies.get("isSupervisor") &&
      !JSON.parse(Cookies.get("isSupervisor"))
    ) {
      setSupervisorStatus(false);
    }
  };

  useEffect(() => {
    getSupervisorStatus();
  }, []);

  // const menuController = (listInQuestion) => {
  //   let _list = [...listInQuestion];
  //   if (stage === "default") {
  //     _list = _list.filter((item) => item.stage === "default");
  //   } else if (stage === "Planning") {
  //     _list = _list.filter(
  //       (item) =>
  //         (item.stage === "planning" && item.isDisplayBasedOnRoles === true) ||
  //         (item.stage === "default" && item.isDisplayBasedOnRoles === true)
  //     );
  //   } else if (stage === "Quarter 1 Appraisal") {
  //     _list = _list.filter(
  //       (item) =>
  //         (item.stage === "Quarter 1 Appraisal" &&
  //           item.isDisplayBasedOnRoles === true) ||
  //         (item.stage === "default" && item.isDisplayBasedOnRoles === true)
  //     );
  //   } else if (stage === "Quarter 2 Appraisal") {
  //     _list = _list.filter(
  //       (item) =>
  //         (item.stage === "Quarter 2 Appraisal" &&
  //           item.isDisplayBasedOnRoles === true) ||
  //         (item.stage === "default" && item.isDisplayBasedOnRoles === true)
  //     );
  //   } else if (stage === "Quarter 3 Appraisal") {
  //     _list = _list.filter(
  //       (item) =>
  //         (item.stage === "Quarter 3 Appraisal" &&
  //           item.isDisplayBasedOnRoles === true) ||
  //         (item.stage === "default" && item.isDisplayBasedOnRoles === true)
  //     );
  //   } else if (stage === "End of Year Appraisal") {
  //     _list = _list.filter(
  //       (item) =>
  //         (item.stage === "End of Year Appraisal" &&
  //           item.isDisplayBasedOnRoles === true) ||
  //         (item.stage === "default" && item.isDisplayBasedOnRoles === true)
  //     );
  //   } else {
  //     _list = _list.filter(
  //       (item) =>
  //         item.stage === "Quarter 1 Appraisal" || item.stage === "default"
  //     );
  //   }

  //   return _list;
  // };

  return (
    <nav className="page-sidebar">
      {supervisorStatus}
      <div className="sidebar-header">
        <img src={EdsgLogo} alt="logo" className="brand" width="150" />
      </div>

      <div className="sidebar-menu">
        <ul className="menu-items">
          <li className="active">
            <AiOutlineHome className="icon" />
            <Link to={"/admin-portal/home"}>
              <span className="title">Home</span>
            </Link>
          </li>
          <li>
            <BiEnvelope className="icon" />
            <Link to={"#"}>
              <span className="title">User Manual</span>
            </Link>
          </li>
          <li>
            <RiBarChartFill className="icon" />
            <Link to={"/admin-portal/appraisal-dashboard"}>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          {supervisorStatus && stage === performanceStages?.PLANNING && (
            <li>
              <RiBarChartFill className="icon" />
              <Link to={"/admin-portal/planning-review"}>
                <span className="title">Planning Review</span>
              </Link>
            </li>
          )}
          {!supervisorStatus && stage === performanceStages?.PLANNING && (
            <li>
              <RiBarChartFill className="icon" />
              <Link to={"/admin-portal/performance-planning"}>
                <span className="title">Performance Planning</span>
              </Link>
            </li>
          )}
          {supervisorStatus &&
            stage === performanceStages?.QUARTER_ONE_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-review"}>
                  <span className="title">Quarter One Review</span>
                </Link>
              </li>
            )}
          {!supervisorStatus &&
            stage === performanceStages?.QUARTER_ONE_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-entry"}>
                  <span className="title">Quarter One Entry</span>
                </Link>
              </li>
            )}
          {supervisorStatus &&
            stage === performanceStages?.QUARTER_TWO_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-review"}>
                  <span className="title">Quarter Two Review</span>
                </Link>
              </li>
            )}
          {!supervisorStatus &&
            stage === performanceStages?.QUARTER_TWO_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-entry"}>
                  <span className="title">Quarter Two Entry</span>
                </Link>
              </li>
            )}
          {supervisorStatus &&
            stage === performanceStages?.QUARTER_THREE_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-review"}>
                  <span className="title">Quarter Three Review</span>
                </Link>
              </li>
            )}
          {!supervisorStatus &&
            stage === performanceStages?.QUARTER_THREE_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-entry"}>
                  <span className="title">Quarter Three Entry</span>
                </Link>
              </li>
            )}
          {supervisorStatus &&
            stage === performanceStages?.END_OF_YEAR_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-review"}>
                  <span className="title">Quarter Four Review</span>
                </Link>
              </li>
            )}
          {!supervisorStatus &&
            stage === performanceStages?.END_OF_YEAR_APPRAISAL && (
              <li>
                <RiBarChartFill className="icon" />
                <Link to={"/admin-portal/quarterly-entry"}>
                  <span className="title">Quarter Four Entry</span>
                </Link>
              </li>
            )}

          <>
            {supervisorStatus && (
              <>
                <li>
                  <AiOutlineHome className="icon" />
                  <>
                    <Link
                      onClick={() => setIsShowingSub(!isShowingSub)}
                      className="has-sub-menu"
                    >
                      <span className="title">Appraisal Settings</span>
                    </Link>

                    <IoIosArrowBack
                      className={`${isShowingSub ? "open" : ""} arrow`}
                    />
                  </>
                </li>
                {isShowingSub && (
                  <ul className={`${isShowingSub ? "show" : ""} sub-menu`}>
                    <li className="">
                      <Link to="/admin-portal/appraisal-period">
                        Appraisal Period
                      </Link>
                    </li>
                    <li className="">
                      <Link to="/admin-portal/appraisal-rating">
                        Appraisal Rating
                      </Link>
                    </li>
                  </ul>
                )}
              </>
            )}
          </>

          {supervisorStatus && (
            <li>
              <RiBarChartFill className="icon" />
              <Link to={"/admin-portal/approved-list"}>
                <span className="title">Approved</span>
              </Link>
            </li>
          )}

          <li>
            <BiLogOutCircle className="icon" />
            <Link to={"/admin-portal/logout"}>
              <span className="title">Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const MenuItem = ({ props: { title, href, icon, children }, pathname }) => {
  const [isShowingSub, setIsShowingSub] = useState(false);

  return (
    <>
      <li className={`${pathname === href ? "active" : ""}`}>
        {icon}
        {children ? (
          <>
            <Link
              onClick={() => setIsShowingSub(!isShowingSub)}
              className="has-sub-menu"
            >
              <span className="title">{title}</span>
            </Link>
            {children && (
              <IoIosArrowBack
                className={`${isShowingSub ? "open" : ""} arrow`}
              />
            )}
          </>
        ) : (
          <Link to={href}>
            <span className="title">{title}</span>
          </Link>
        )}
      </li>
      {children && isShowingSub && (
        <ul className={`${isShowingSub ? "show" : ""} sub-menu`}>
          {children &&
            children
              .filter((item) => item.isDisplayBasedOnRoles === true)
              .map((sub) => (
                <li
                  className={`${pathname === sub.href ? "active" : ""}`}
                  key={sub.title}
                >
                  <Link to={sub.href}>{sub.title}</Link>
                </li>
              ))}
        </ul>
      )}
    </>
  );
};

export default Sidebar;
