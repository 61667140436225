
import {
    GET_PERFORMANCE_DETAILS,
    SET_PERFORMANCE_DETAILS_LOADER,
  } from "../actions/actionsTypes";
  
  const INITIAL_STATE = {
    performanceDetails: {},
    isLoading: false,
  };


const performanceDetailsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case GET_PERFORMANCE_DETAILS:
        return {
          ...state,
          performanceDetails: action.payload,
        };
  
      case SET_PERFORMANCE_DETAILS_LOADER:
        return {
          ...state,
          isLoading: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default performanceDetailsReducer;