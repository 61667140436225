import React, { useState } from "react";

import { IoMdClose } from "react-icons/io";
import TextInput from "../inputs/TextInput";

const CreateCompetencyForm = ({ closeModal, handleCreate }) => {
  const [inputData, setInputData] = useState();

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <TextInput
            className="w-100 m-b-10"
            name="competency"
            label="Competency"
            type="text"
            onChange={handleChange}
          />
          <TextInput
            className="w-100 m-b-10"
            name="priority"
            label="Sequence"
            type="text"
            onChange={handleChange}
          />

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={() => handleCreate(inputData)}
          >
            Save Record
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateCompetencyForm;
