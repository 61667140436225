import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { RiAddLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  getPerformanceGoalDetails,
  getPerformanceGoalDetailsWithData,
  submitSupervisorScore,
} from "../../network/services";
import {
  GET_PERFORMANCE_DETAILS,
  SUBMIT_SUPERVISOR_SCORE,
} from "../../redux/actions/actionsTypes";
import { feedback } from "../../utils/feedback";
import Spinner from "../common/spinner/spinner";

const ReviewGoalsModal = ({ goal, setReviewGoal, closeModal }) => {
  const [goalCheck] = useState(goal[0]);

  const dispatch = useDispatch();
  let payload = [];

  const fetchPerformanceGoalDetailsWithData = async (data) => {
    try {
      let response;
      if (data !== null) {
        response = await getPerformanceGoalDetailsWithData(data);
      } else {
        response = await getPerformanceGoalDetails();
      }

      if (response.status === 200) {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,

          payload: response?.data?.data,
        });
        setReviewGoal(response?.data?.data);
      } else {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_PERFORMANCE_DETAILS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleSubmitSupervisorScore = async (data, id) => {
    try {
      const response = await submitSupervisorScore(data);
      if (response.status === 200) {
        dispatch({
          type: SUBMIT_SUPERVISOR_SCORE,
          payload: response?.data?.data,
        });
        fetchPerformanceGoalDetailsWithData(id);

        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: SUBMIT_SUPERVISOR_SCORE,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_SUPERVISOR_SCORE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const periodDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const supervisorLoader = useSelector(
    (state) => state?.submitSupervisorScore?.isLoading
  );

  const handleChangeScore = (goal, score) => {
    let index = payload.findIndex((value) => value.id === goal.id);
    if (index < 0) {
      payload.push({
        id: goal?.id,
        performanceId: goal?.performanceID,
        [periodComment(periodDetails?.stage)]: "",
        [periodScore(periodDetails?.stage)]: parseInt(score),
      });
    } else {
      payload[index] = {
        ...payload[index],
        [periodScore(periodDetails?.stage)]: parseInt(score),
      };
    }
  };

  const submit = () => {
    handleSubmitSupervisorScore(payload, goalCheck.performanceID);
  };

  const periodComment = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneComment";
      case "Quarter 2 Appraisal":
        return "quaterTwoComment";
      case "Quarter 3 Appraisal":
        return "quaterThreeComment";
      case "Quarter 4 Appraisal":
        return "quaterFourComment";
    }
  };

  const periodScore = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneScore";
      case "Quarter 2 Appraisal":
        return "quaterTwoScore";
      case "Quarter 3 Appraisal":
        return "quaterThreeScore";
      case "Quarter 4 Appraisal":
        return "quaterFourScore";
    }
  };

  const periodSupervisorScore = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneSupervisorScore";
      case "Quarter 2 Appraisal":
        return "quaterTwoSupervisorScore";
      case "Quarter 3 Appraisal":
        return "quaterThreeSupervisorScore";
      case "Quarter 4 Appraisal":
        return "quaterFourSupervisorScore";
    }
  };

  const periodSupervisorComment = (period) => {
    switch (period) {
      case "Quarter 1 Appraisal":
        return "quaterOneSupervisorComment";
      case "Quarter 2 Appraisal":
        return "quaterTwoSupervisorComment";
      case "Quarter 3 Appraisal":
        return "quaterThreeSupervisorComment";
      case "Quarter 4 Appraisal":
        return "quaterFourSupervisorComment";
    }
  };

  const handleChangeComment = (goal, comment) => {
    let index = payload.findIndex((value) => value.id === goal.id);
    if (index < 0) {
      payload.push({
        id: goal?.id,
        performanceId: goal?.performanceID,
        [periodScore(periodDetails?.stage)]: 0,
        [periodComment(periodDetails?.stage)]: "",
      });
    } else {
      payload[index] = {
        ...payload[index],
        [periodComment(periodDetails?.stage)]: comment,
      };
    }
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box w-100 " style={{ overflowX: "scroll" }}>
        <div className="content cloak">
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p>Targets/Goals</p>
                </div>
                <div className="th">
                  <p>KPIs</p>
                </div>
                <div className="th">
                  <p>Weight</p>
                </div>
                <div className="th">
                  <p>Sequence</p>
                </div>
                <div className="th">
                  <p>Q1 Score (../100)</p>
                </div>
                <div className="th flex space-between ">
                  <p>Q1 Comment</p>
                </div>
                <div className="th">
                  <p>Q2 Score (../100)</p>
                </div>
                <div className="th flex space-between ">
                  <p>Q2 Comment</p>
                </div>
                <div className="th">
                  <p>Q3 Score (../100)</p>
                </div>
                <div className="th flex space-between ">
                  <p>Q3 Comment</p>
                </div>
                <div className="th">
                  <p>Q4 Score (../100)</p>
                </div>
                <div className="th flex space-between ">
                  <p>Q4 Comment</p>
                </div>
                <div className="th">
                  <p>Supervisor's Score (../100)</p>
                </div>
                <div className="th flex space-between ">
                  <p>Supervisor's Comment</p>
                </div>
                <div className="th"></div>
              </div>
            </div>
            {supervisorLoader ? (
              <Spinner />
            ) : (
              <div className="table-body">
                {goal?.map((goal) => (
                  <div className="table-row" key={goal.id}>
                    <div className="td">{goal?.objective}</div>
                    <div className="td">{goal.performanceIndicator}</div>
                    <div className="td">{goal.weight}</div>
                    <div className="td">{goal.priority}</div>
                    <div className="td">{goal.quaterOneScore}</div>
                    <div className="td">{goal.quaterOneComment}</div>
                    <div className="td">{goal.quaterTwoScore}</div>
                    <div className="td">{goal.quaterTwoComment}</div>
                    <div className="td">{goal.quaterThreeScore}</div>
                    <div className="td">{goal.quaterThreeComment}</div>
                    <div className="td">{goal.quaterFourScore}</div>
                    <div className="td">{goal.quaterFourComment}</div>
                    {/* <div className="td">
                      {goal[periodScore(periodDetails?.stage)] &&
                        goal[periodScore(periodDetails?.stage)]}
                    </div>
                    <div className="td">
                      {goal[periodComment(periodDetails?.stage)] &&
                        goal[periodComment(periodDetails?.stage)]}
                    </div> */}
                    <div className="td">
                      {" "}
                      {goal[periodSupervisorScore(periodDetails?.stage)] ? (
                        goal[periodSupervisorScore(periodDetails?.stage)]
                      ) : (
                        <input
                          defaultValue={0}
                          type="number"
                          className="table-input"
                          onChange={(e) =>
                            handleChangeScore(goal, e.target.value)
                          }
                        />
                      )}
                    </div>
                    <div className="td">
                      {goal[periodSupervisorComment(periodDetails?.stage)] ? (
                        goal[periodSupervisorComment(periodDetails?.stage)]
                      ) : (
                        <textarea
                          style={{ padding: "0.5rem" }}
                          placeholder="comment if any..."
                          name="quarter comment"
                          id=""
                          cols="50"
                          rows="2"
                          onChange={(e) =>
                            handleChangeComment(goal, e.target.value)
                          }
                        ></textarea>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* {goalCheck[periodSupervisorComment(periodDetails?.stage)] && (
            <button
              className="add-button pointer"
              type="button"
              onClick={() => submit()}
            >
              <RiAddLine />
              Submit Supervisor Details
            </button>
          )} */}
          <button
            className="add-button pointer"
            type="button"
            onClick={() => submit()}
          >
            <RiAddLine />
            Submit Supervisor Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewGoalsModal;
