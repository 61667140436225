import { feedback } from "../../utils/feedback";
import {
  getCompetencyCategory,
  getDashboardDetails,
  getPerformanceGoalDetailsWithData,
  getRoles,
  submit,
  submitSupervisor,
  getGoals,
  getPerformanceGoalDetails,
  submitScore,
  submitSupervisorScore,
  submitDevelopmentNeed,
  updateDevelopmentNeed,
  deleteDevelopmentNeed,
  getDevelopmentNeed,
  getMyPerformanceApprovalRoute,
} from "../../network/services";
import {
  GET_DASHBOARD_DETAILS,
  GET_ROLES,
  SET_DASHBOARD_DETAILS_LOADER,
  GET_COMPETENCY_CATEGORY,
  GET_COMPETENCY_CATEGORY_LOADER,
  GET_PERFORMANCE_DETAILS,
  SET_PERFORMANCE_DETAILS_LOADER,
  GET_GOALS,
  SET_GOALS_LOADER,
  SUBMIT_SCORE,
  SUBMIT_SUPERVISOR_SCORE,
  SUBMIT_SCORE_LOADER,
  SUBMIT_SUPERVISOR_SCORE_LOADER,
  SUBMIT_APPROVAL_SUPERVISOR_LOADER,
  SUBMIT_APPROVAL_SUPERVISOR,
  SUBMIT_APPROVAL,
  SUBMIT_APPROVAL_LOADER,
  SUBMIT_DEVELOPMENT_NEEDS,
  UPDATE_DEVELOPMENT_NEEDS,
  DELETE_DEVELOPMENT_NEEDS,
  GET_DEVELOPMENT_NEEDS,
  DEVELOPMENT_NEEDS_LOADER,
  GET_MY_APPROVAL_ROUTE,
  GET_MY_APPROVAL_ROUTE_LOADER,
} from "./actionsTypes";

export const fetchDashboardDetails = () => {
  return async (dispatch) => {
    dispatch({ type: SET_DASHBOARD_DETAILS_LOADER, payload: true });
    try {
      const response = await getDashboardDetails();
      if (response.status === 200) {
        dispatch({
          type: GET_DASHBOARD_DETAILS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_DASHBOARD_DETAILS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_DASHBOARD_DETAILS,
        payload: null,
      });
    }
    dispatch({ type: SET_DASHBOARD_DETAILS_LOADER, payload: false });
  };
};

export const fetchRoles = () => {
  return async (dispatch) => {
    try {
      const response = await getRoles();

      if (response.status === 200) {
        dispatch({
          type: GET_ROLES,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_ROLES,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_ROLES,
        payload: null,
      });
    }
  };
};

export const fetchPerformanceDetails = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_PERFORMANCE_DETAILS_LOADER, payload: true });
    try {
      let response;
      if (data !== null) {
        response = await getPerformanceGoalDetailsWithData(data);
      } else {
        response = await getPerformanceGoalDetails();
      }

      if (response.status === 200) {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,

          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_PERFORMANCE_DETAILS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_PERFORMANCE_DETAILS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: SET_PERFORMANCE_DETAILS_LOADER, payload: false });
  };
};

export const fetchCompetencyCategory = () => {
  return async (dispatch) => {
    dispatch({ type: GET_COMPETENCY_CATEGORY_LOADER, payload: true });
    try {
      const response = await getCompetencyCategory();

      if (response.status === 200) {
        dispatch({
          type: GET_COMPETENCY_CATEGORY,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_COMPETENCY_CATEGORY,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_COMPETENCY_CATEGORY,
        payload: [],
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: GET_COMPETENCY_CATEGORY_LOADER, payload: false });
  };
};

export const fetchGoals = (data, query) => {
  return async (dispatch) => {
    dispatch({ type: SET_GOALS_LOADER, payload: true });
    let response;
    try {
      if (data) {
        response = await getPerformanceGoalDetailsWithData(data);
      } else {
        response = await getGoals(query);
      }

      if (response.status === 200) {
        dispatch({
          type: GET_GOALS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_GOALS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_GOALS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }

    dispatch({ type: SET_GOALS_LOADER, payload: false });
  };
};

export const handleSubmitScore = (data, id) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_SCORE_LOADER, payload: true });
    try {
      const response = await submitScore(data);
      if (response.status === 200) {
        dispatch({
          type: SUBMIT_SCORE,
          payload: response?.data?.data,
        });

        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        dispatch(fetchPerformanceDetails(id));
        // window.location.reload();
      } else {
        dispatch({
          type: SUBMIT_SCORE,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_SCORE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: SUBMIT_SCORE_LOADER, payload: false });
  };
};

export const handleSubmitSupervisorScore = (data) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_SUPERVISOR_SCORE_LOADER, payload: true });
    try {
      const response = await submitSupervisorScore(data);
      if (response.status === 200) {
        dispatch({
          type: SUBMIT_SUPERVISOR_SCORE,
          payload: response?.data?.data,
        });
        dispatch(fetchPerformanceDetails());
        // window.location.reload();
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: SUBMIT_SUPERVISOR_SCORE,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_SUPERVISOR_SCORE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: SUBMIT_SUPERVISOR_SCORE_LOADER, payload: false });
  };
};

export const handleSubmitApproval = (data) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_APPROVAL_LOADER, payload: true });
    try {
      const response = await submit(data);
      if (response.status === 200) {
        dispatch({
          type: SUBMIT_APPROVAL,
          payload: response?.data?.data,
        });
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: SUBMIT_APPROVAL,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_APPROVAL,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: SUBMIT_APPROVAL_LOADER, payload: false });
  };
};

export const handleSubmitSupervisorApproval = (data, stage, comments) => {
  return async (dispatch) => {
    dispatch({ type: SUBMIT_APPROVAL_SUPERVISOR_LOADER, payload: true });
    try {
      const response = await submitSupervisor(data, stage, comments);

      if (response.status === 200) {
        dispatch({
          type: SUBMIT_APPROVAL_SUPERVISOR,
          payload: response?.data?.data,
        });
        window.location.reload();
        dispatch(fetchGoals(data));
        dispatch(fetchGoals());
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: SUBMIT_APPROVAL_SUPERVISOR,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_APPROVAL_SUPERVISOR,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: SUBMIT_APPROVAL_SUPERVISOR_LOADER, payload: false });
  };
};

export const handleSubmitDevelopmentNeed = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: true });
    try {
      const response = await submitDevelopmentNeed(data);
      if (response.status === 200) {
        dispatch({
          type: SUBMIT_DEVELOPMENT_NEEDS,
          payload: response?.data?.data,
        });
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: SUBMIT_DEVELOPMENT_NEEDS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: SUBMIT_DEVELOPMENT_NEEDS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: false });
  };
};

export const handleUpdateDevelopmentNeed = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: true });
    try {
      const response = await updateDevelopmentNeed(data);
      if (response.status === 200) {
        dispatch({
          type: UPDATE_DEVELOPMENT_NEEDS,
          payload: response?.data?.data,
        });
        dispatch(handleGetDevelopmentNeed(data));
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: UPDATE_DEVELOPMENT_NEEDS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: UPDATE_DEVELOPMENT_NEEDS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: false });
  };
};

export const handleDeleteDevelopmentNeed = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: true });
    try {
      const response = await deleteDevelopmentNeed(data);
      if (response.status === 200) {
        dispatch({
          type: DELETE_DEVELOPMENT_NEEDS,
          payload: response?.data?.data,
        });
        dispatch(handleGetDevelopmentNeed(data));
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
      } else {
        dispatch({
          type: DELETE_DEVELOPMENT_NEEDS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: DELETE_DEVELOPMENT_NEEDS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: false });
  };
};

export const handleGetDevelopmentNeed = (data) => {
  return async (dispatch) => {
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: true });
    try {
      const response = await getDevelopmentNeed(data);
      if (response.status === 200) {
        dispatch({
          type: GET_DEVELOPMENT_NEEDS,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_DEVELOPMENT_NEEDS,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_DEVELOPMENT_NEEDS,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: DEVELOPMENT_NEEDS_LOADER, payload: false });
  };
};

const fetchPerformanceApprovalRoute = () => {
  return async (dispatch) => {
    dispatch({ type: GET_MY_APPROVAL_ROUTE_LOADER, payload: true });
    try {
      const response = await getMyPerformanceApprovalRoute();
      if (response.status === 200) {
        dispatch({
          type: GET_MY_APPROVAL_ROUTE,
          payload: response?.data?.data,
        });
      } else {
        dispatch({
          type: GET_MY_APPROVAL_ROUTE,
          payload: null,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_MY_APPROVAL_ROUTE,
        payload: null,
      });
      feedback({
        title: "Failed",
        text: e?.response?.data?.message,
        iconType: "error",
      });
    }
    dispatch({ type: GET_MY_APPROVAL_ROUTE_LOADER, payload: false });
  };
};
