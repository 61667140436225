import React, { useEffect, useState } from "react";

import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import LocationDetails from "../../../layouts/LocationDetails";
import {
  delAppraisalRatings,
  getAppraisalRatings,
  postAppraisalRatings,
  putAppraisalRatings,
} from "../../../../network/services";
import { feedback } from "../../../../utils/feedback";
import IsLoading from "../../../common/isLoading";
import AppraisalRatingsForm from "../../../layouts/appraisalRatingsForm";
import EditAppraisalRatingsForm from "../../../layouts/editAppraisalRatingsForm";
import Cookies from "js-cookie";

const AppraisalRating = ({ history, details = {} }) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [appraisalRatings, setAppraisalRatings] = useState([]);
  const [isFetchingAppraisalRatings, setIsFetchingAppraisalRatings] =
    useState(false);
  const [selectedAppraisalRatings, setSelectedAppraisalRatings] =
    useState(null);
  const [isShowEditRatingsForm, setIsShowEditRatingsForm] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Employee Profile";
  }, []);

  const fetchAppraisalRatings = async () => {
    setIsFetchingAppraisalRatings(true);
    try {
      const response = await getAppraisalRatings();
      if (response?.status === 200) {
        setAppraisalRatings(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingAppraisalRatings(false);
  };

  const handleSave = async (data) => {
    try {
      const payload = {
        ...data,
        fromScore: Number(data?.fromScore),
        toScore: Number(data?.toScore),
      };
      const response = await postAppraisalRatings(payload);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchAppraisalRatings();
        setShowFormModal(false);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleEditAppraisalRatings = (item) => {
    setIsShowEditRatingsForm(true);
    setSelectedAppraisalRatings(item);
  };

  const editAppraisalRatings = async (data) => {
    try {
      const payload = {
        ...data,
        fromScore: Number(data?.fromScore),
        toScore: Number(data?.toScore),
      };
      const response = await putAppraisalRatings(payload);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchAppraisalRatings();
        setIsShowEditRatingsForm(false);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleDeleteAppraisalRatings = async (data) => {
    const id = data?.id;

    try {
      const response = await delAppraisalRatings(id);
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchAppraisalRatings();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  useEffect(() => {
    fetchAppraisalRatings();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Setup Appraisal Rating</h2>
        <LocationDetails />
      </div>

      {isFetchingAppraisalRatings ? (
        <IsLoading />
      ) : (
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              <div className="th">
                <p className="san-sherif">Grade</p>
              </div>
              <div className="th">
                <p className="san-sherif">Score</p>
              </div>
              <div className="th">
                <p className="san-sherif">Description</p>
              </div>
              <div className="th">
                <p className="san-sherif">Last Updated By</p>
              </div>
              <div className="th">
                {JSON.parse(Cookies.get("isSupervisor")) === true && (
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => setShowFormModal(true)}
                  >
                    <RiAddLine />
                    Add Rate
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="table-body">
            {appraisalRatings.map((item) => (
              <div className="table-row" key={item.id}>
                <div className="td">{item?.grade}</div>
                <div className="td">
                  {item?.fromScore} - {item?.toScore}
                </div>
                <div className="td">{item?.ratingDescription}</div>
                <div className="td">
                  {item?.modifiedBy} {item?.date}{" "}
                </div>
                {JSON.parse(Cookies.get("isSupervisor")) === true && (
                  <div className="td actions">
                    <button
                      type="button"
                      className="pointer"
                      onClick={() => handleEditAppraisalRatings(item)}
                    >
                      <BiEditAlt />
                    </button>

                    <button
                      type="button"
                      className="del pointer"
                      onClick={() => handleDeleteAppraisalRatings(item)}
                    >
                      <GiTrashCan />
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {showFormModal && (
        <AppraisalRatingsForm
          closeModal={() => setShowFormModal(false)}
          handleSave={handleSave}
        />
      )}

      {isShowEditRatingsForm && (
        <EditAppraisalRatingsForm
          closeModal={() => setIsShowEditRatingsForm(false)}
          handleEdit={editAppraisalRatings}
          selectedAppraisalRatings={selectedAppraisalRatings}
        />
      )}
    </div>
  );
};

export default AppraisalRating;
