import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";
import { getStaffByRoles } from "../../network/services";

const PlanningApprovalForm = ({ closeModal, handleSave }) => {
  const [inputData, setInputData] = useState();
  const [staff, setStaff] = useState([]);

  const roles = useSelector((state) => state?.roles.roles);

  const fetchStaffByRole = async (roleId) => {
    try {
      const response = await getStaffByRoles(roleId);
      if (response?.status === 200) {
        setStaff(response?.data?.data);
      }
    } catch (error) {}
  };

  const formatList = (listInQuestion) => {
    if (listInQuestion && listInQuestion.length > 0) {
      const result = listInQuestion.map((listItem) => {
        return {
          name: listItem.name,
          value: listItem.id,
        };
      });

      return result;
    }

    return [];
  };

  const handleChange = (e) => {
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleRolesChange = (e) => {
    const value = e.target.value;
    setInputData((prev) => ({
      ...prev,
      [e.target.name]: value,
    }));
    fetchStaffByRole(value);
  };

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box max-w-400">
        <div className="content">
          <SelectInput
            className="w-100 m-b-10"
            label="Role"
            options={formatList(roles)}
            onChange={handleRolesChange}
          />
          <SelectInput
            className="w-100 m-b-10"
            label="Staff"
            options={formatList(staff)}
            onChange={handleChange}
          />
          <TextInput
            className="w-100 m-b-10"
            name="sequence"
            label="Sequence"
            type="text"
            onChange={handleChange}
          />

          <button
            type="button"
            className="btn btn-large w-100"
            onClick={() => handleSave(inputData)}
          >
            Save Record
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanningApprovalForm;
