/* eslint-disable import/no-anonymous-default-export */
import React from "react";

import { RiBarChartFill } from "react-icons/ri";
import { BiEnvelope } from "react-icons/bi";
import { BiLogOutCircle } from "react-icons/bi";
import { AiOutlineHome } from "react-icons/ai";
import { GrUserSettings } from "react-icons/gr";

import Cookies from "js-cookie";

export default [
  {
    title: "Home",
    href: "/admin-portal/home",
    icon: <AiOutlineHome className="icon" />,
    stage: "default",
    isDisplayBasedOnRoles: true,
  },
  {
    title: "User Manual",
    href: "#",
    icon: <BiEnvelope className="icon" />,
    stage: "default",
    isDisplayBasedOnRoles: true,
  },
  {
    title: "Dashboard",
    href: "/admin-portal/appraisal-dashboard",
    icon: <RiBarChartFill className="icon" />,
    stage: "default",
    isDisplayBasedOnRoles: true,
  },
  {
    title: "Performance Planning",
    href: "/admin-portal/performance-planning",
    icon: <RiBarChartFill className="icon" />,
    stage: "planning",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === false
        ? true
        : false,
  },
  {
    title: "Planning Review",
    href: "/admin-portal/planning-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "planning",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },
  {
    title: "Quarter One Entry",
    href: "/admin-portal/quarterly-entry",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 1 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === false
        ? true
        : false,
  },

  {
    title: "Quarter One Review",
    href: "/admin-portal/quarterly-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 1 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },

  {
    title: "Quarter Two Entry",
    href: "/admin-portal/quarterly-entry",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 2 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === false
        ? true
        : false,
  },

  {
    title: "Quarter Two Review",
    href: "/admin-portal/quarterly-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 2 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },

  {
    title: "Quarter Three Entry",
    href: "/admin-portal/quarterly-entry",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 3 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === false
        ? true
        : false,
  },

  {
    title: "Quarter Three Review",
    href: "/admin-portal/quarterly-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "Quarter 3 Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },

  {
    title: "Quarter Four Entry",
    href: "/admin-portal/quarterly-entry",
    icon: <RiBarChartFill className="icon" />,
    stage: "End of Year Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === false
        ? true
        : false,
  },

  {
    title: "Quarter Four Review",
    href: "/admin-portal/quarterly-review",
    icon: <RiBarChartFill className="icon" />,
    stage: "End of Year Appraisal",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },
  {
    title: "Appraisal Settings",
    href: "",
    icon: <GrUserSettings className="icon" />,
    children: [
      {
        title: "Appraisal Period",
        href: "/admin-portal/appraisal-period",
        icon: "CP",
        isDisplayBasedOnRoles: true,
      },
      {
        title: "Appraisal Rating",
        href: "/admin-portal/appraisal-rating",
        icon: "ED",
        isDisplayBasedOnRoles: true,
      },
    ],
    stage: "default",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },
  {
    title: "Approved",
    href: "/admin-portal/approved-list",
    icon: <RiBarChartFill className="icon" />,
    stage: "default",
    isDisplayBasedOnRoles:
      Cookies.get("isSupervisor") &&
      JSON.parse(Cookies.get("isSupervisor")) === true
        ? true
        : false,
  },
  {
    title: "Logout",
    href: "/admin-portal/logout",
    icon: <BiLogOutCircle className="icon" />,
    stage: "default",
    isDisplayBasedOnRoles: true,
  },
];
