import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { GoPrimitiveDot } from "react-icons/go";
import { getPerformanceDetailApprovalProgress } from "../../network/services";
import IsLoading from "../common/isLoading";
import { feedback } from "../../utils/feedback";

const ViewApprovalProgress = ({ closeModal }) => {
  const [approvalProgress, setApproverProgress] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const competencyCategory = useSelector(
    (state) => state?.competencyReducer.competencyCategory
  );

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  const isLoading = useSelector((state) => state?.competencyReducer.isLoading);

  const viewApprovalProgress = async () => {
    setIsFetching(true);
    try {
      const response = await getPerformanceDetailApprovalProgress(
        dashboardDetails?.performanceId
      );

      if (response?.status === 200) {
        setApproverProgress(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetching(false);
  };

  const renderBadge = (status) => {
    switch (status) {
      case 1:
        return (
          <div style={{ display: "flex" }}>
            <GoPrimitiveDot color="green" size={50} />
            <span style={{ display: "inline-block", marginTop: "1rem" }}>
              Approved
            </span>
          </div>
        );
      case 2:
        return (
          <div style={{ display: "flex" }}>
            <GoPrimitiveDot color="#FFBF00" size={50} />
            <span style={{ display: "inline-block", marginTop: "1rem" }}>
              Pending
            </span>
          </div>
        );
      default:
        return <GoPrimitiveDot />;
    }
  };

  useEffect(() => {
    viewApprovalProgress();
  }, []);

  return (
    <div className="overlay">
      <IoMdClose className="close-btn pointer" onClick={closeModal} />
      <div className="modal-box" style={{ width: "50%" }}>
        <div className="content">
          {isFetching ? (
            <IsLoading />
          ) : (
            <div className="table-view m-t-10">
              <div className="table-header">
                <div className="table-row">
                  <div className="th">
                    <p className="san-sherif">Approver</p>
                  </div>

                  <div className="th">
                    <p className="san-sherif">Comment/Date Approved</p>
                  </div>
                  <div className="th">Status</div>
                </div>
              </div>
              <div className="table-body">
                {approvalProgress?.map((item) => (
                  <div className="table-row" key={item?.id}>
                    <div className="td">
                      {item?.approver}
                      <br />
                      {`(${item?.role})`}
                    </div>
                    <div className="td">{item?.comment}</div>
                    <div className="td">{renderBadge(item?.status)} </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewApprovalProgress;
