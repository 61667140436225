import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineEdit } from "react-icons/ai";
import { RiAddLine } from "react-icons/ri";
import LocationDetails from "../../../../layouts/LocationDetails";
import {
  getPerformanceGoalsData,
  getPerformanceHeader,
  postInitiatePerformance,
  postInitiatePerformanceApproval,
  postPerformnaceGoal,
  getMyPerformanceApprovalRoute,
  getPerformanceStages,
} from "../../../../../network/services";
import { feedback } from "../../../../../utils/feedback";
import IsLoading from "../../../../common/isLoading";
import Spinner from "../../../../common/spinner/spinner";
import GoalForm from "../../../../layouts/goalForm";
import ViewApprovalProgress from "../../../../layouts/viewApprovalProgressModal";
import { getTotalWeight } from "../helper";
import { fetchDashboardDetails } from "../../../../../redux/actions/actions";
import ExpectationsModal from "../../../../layouts/expectationsModal";

const PerformancePlanning = ({ history, details = {} }) => {
  const [showGoalFormModal, setshowGoalFormModal] = useState(false);
  const [isInitiatingPerformance, setIsInitiatePerformance] = useState(false);
  const [headerDetails, setHeaderDetails] = useState(null);
  const [isFetchingPerformanceHeader, setIsFetchingPerformanceHeader] =
    useState(false);
  const [isFetchingGoals, setIsFetchingGoals] = useState(false);
  const [goalsAndCommitments, setGoalsAndCommitments] = useState([]);
  const [showApprovalProgress, setShowApprovalProgress] = useState(false);
  const [approvalProgress, setApprovalProgress] = useState({});
  const [loading, setLoading] = useState(false);
  const [performanceStages, setPerformanceStages] = useState([]);
  const [isShowExpectationsModal, setIsShowExpectationsModal] = useState(false);
  const [expectations, setExpectations] = useState([]);

  const dispatch = useDispatch();

  const dashboardDetails = useSelector(
    (state) => state?.dashboard?.dashboardDetails
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Performance Planning";
  }, []);

  const handleExpectations = (goalExpectations) => {
    return setExpectations(goalExpectations);
  };

  const handleApprovalProgress = async () => {
    setIsFetchingPerformanceHeader(true);
    try {
      const response = await getMyPerformanceApprovalRoute();
      if (response?.status) {
        let result = response?.data?.data;
        setApprovalProgress(result);
        setShowApprovalProgress(true);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingPerformanceHeader(false);
  };

  const fetchPerformanceHeaderDetails = async (id) => {
    setIsFetchingPerformanceHeader(true);
    try {
      const response = await getPerformanceHeader(id);
      if (response?.status) {
        let result = response?.data?.data;

        setHeaderDetails(result);
        if (result) {
          fetchGoalsAndCommitments(result.id);
        }
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingPerformanceHeader(false);
  };

  const handleInitiatePerformance = async () => {
    setIsInitiatePerformance(true);
    try {
      const response = await postInitiatePerformance(
        dashboardDetails?.periodId
      );
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        dispatch(fetchDashboardDetails());
        fetchPerformanceHeaderDetails(dashboardDetails?.periodId);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsInitiatePerformance(false);
  };

  const fetchGoalsAndCommitments = async (id) => {
    setIsFetchingGoals(true);
    try {
      const response = await getPerformanceGoalsData(id);

      if (response?.status) {
        setGoalsAndCommitments(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingGoals(false);
  };

  const handleSave = async (e, data) => {
    setLoading(true);
    e.preventDefault();

    const { inputData, expectationList } = data;

    if (expectationList.length < performanceStages.length - 1) {
      setLoading(false);
      return feedback({
        title: "Failed",
        text: "Check that you have entered expectation for all quarters",
        iconType: "error",
      });
    }

    const refinedExpectationList = expectationList.map((item) => {
      return {
        stageId: item?.stageId,
        initialExpectation: item?.initialExpectation,
      };
    });
    // Ask questions about staffId. StaffId returned from the headers endpoint is null
    const payload = {
      ...inputData,
      priority: Number(inputData?.priority),
      weight: Number(inputData?.weight),
      performanceID: headerDetails?.id,
      periodID: dashboardDetails?.periodId,
      staffID: headerDetails?.staffId ?? 0,
      mdaId: headerDetails?.mdaID,
      expectations: refinedExpectationList,
    };

    try {
      const response = await postPerformnaceGoal(payload);

      if (response?.data?.code === 1 || response?.data?.code === "1") {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchGoalsAndCommitments(headerDetails?.id);
        setshowGoalFormModal(false);
        setLoading(false);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
      setLoading(false);
    }
  };

  const sendForApproval = async () => {
    try {
      const response = await postInitiatePerformanceApproval(
        dashboardDetails?.performanceId
      );

      if (response?.data?.code === 1) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchGoalsAndCommitments(dashboardDetails?.performanceId);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const fetchPerformanceStages = async () => {
    try {
      const response = await getPerformanceStages();
      if (response?.data?.code === 1 || response?.data?.code === "1") {
        const stages = response?.data?.data
          .filter((item) => item.id !== 1)
          .map((stage) => {
            return {
              name: stage?.stages,
              value: stage?.id,
            };
          });
        setPerformanceStages(stages);
      } else {
        alert("Could not retrieve performance stages");
      }
    } catch (error) {
      alert("Could not retrieve performance stages");
    }
  };

  const handleSendForApproval = () => {
    feedback(
      {
        title: "Are you sure",
        text: "Send for approval",
        isDialog: true,
        iconType: "warning",
        confirmButtonText: "Send For Approval",
      },
      sendForApproval
    );
  };

  useEffect(() => {
    fetchPerformanceHeaderDetails(dashboardDetails?.periodId);
    fetchPerformanceStages();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Performance Planning</h2>
        <LocationDetails />
      </div>

      {isFetchingPerformanceHeader ? (
        <IsLoading />
      ) : (
        <div className="table-view m-t-10">
          <div className="table-header">
            <div className="table-row">
              <div className="th">
                <p className="san-sherif">Period</p>
              </div>
              <div className="th">
                <p className="san-sherif">Planning Start Date</p>
              </div>
              <div className="th">
                <p className="san-sherif">Planning End Date</p>
              </div>
              <div className="th">
                <p className="san-sherif">Status</p>
              </div>
              <div className="th">
                {headerDetails === null && (
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={handleInitiatePerformance}
                  >
                    <AiOutlineEdit />
                    {isInitiatingPerformance ? (
                      <Spinner />
                    ) : (
                      "Initiate Performance"
                    )}
                  </button>
                )}
                {headerDetails !== null && (
                  <button
                    className="add-button pointer"
                    type="button"
                    onClick={() => handleApprovalProgress()}
                  >
                    <AiOutlineEdit />
                    View Approval Progress
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="table-body">
            {headerDetails && (
              <div className="table-row">
                <div className="td">{headerDetails?.period}</div>
                <div className="td">{headerDetails?.stageStartDate}</div>
                <div className="td">{headerDetails?.stageEndDate}</div>
                <div className="td">{headerDetails?.approvalStatus}</div>
                <div className="td"></div>
              </div>
            )}
          </div>
        </div>
      )}

      {isFetchingGoals ? (
        <IsLoading />
      ) : (
        <div style={{ overflowX: "scroll" }}>
          <div className="table-view m-t-10">
            <div className="table-header">
              <div className="table-row">
                <div className="th">
                  <p className="san-sherif">Targets/Goals/Commitments</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Expectations</p>
                </div>
                <div className="th">
                  <p className="san-sherif">KPIs</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Sequence</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Weight</p>
                </div>
                <div className="th">
                  <p className="san-sherif">Last Updated By</p>
                </div>

                <div className="th">
                  {getTotalWeight(goalsAndCommitments) !== 100 && (
                    <button
                      className="add-button pointer"
                      type="button"
                      onClick={() => setshowGoalFormModal(true)}
                    >
                      <RiAddLine />
                      Add Goal
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="table-body">
              {goalsAndCommitments?.map((item) => (
                <div className="table-row" key={item.id}>
                  <div className="td">{item?.objective}</div>
                  <div className="td actions">
                    <button
                      type="button"
                      className="btn btn-large w-100 activate"
                      style={{ marginRight: "5px" }}
                      onClick={() => {
                        setIsShowExpectationsModal(true);
                        handleExpectations(item?.expectations);
                      }}
                    >
                      View
                    </button>
                  </div>
                  <div className="td" style={{ width: "15%" }}>
                    {item?.performanceIndicator}
                  </div>
                  <div className="td">{item?.priority}</div>
                  <div className="td">{item?.weight}</div>
                  <div className="td">
                    {item?.modifiedBy} {item?.date}{" "}
                  </div>
                  <div className="td"></div>
                </div>
              ))}
              <div className="table-row">
                <div className="td">
                  {goalsAndCommitments.length > 0 &&
                  goalsAndCommitments[0]?.approvalStatus !== "Staff" ? null : (
                    <button
                      className="add-button pointer"
                      type="button"
                      onClick={handleSendForApproval}
                    >
                      <RiAddLine />
                      Send goals for approval
                    </button>
                  )}
                </div>
                <div className="td"></div>
                <div className="td"></div>
                <div className="td">{getTotalWeight(goalsAndCommitments)}</div>
                <div className="td"></div>
                <div className="td"></div>
                <div className="td"></div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showGoalFormModal && (
        <GoalForm
          closeModal={() => setshowGoalFormModal(false)}
          handleSave={handleSave}
          loading={loading}
          setLoading={setLoading}
          performanceStages={performanceStages}
        />
      )}

      {showApprovalProgress && (
        <ViewApprovalProgress
          approvalProgress={approvalProgress}
          closeModal={() => setShowApprovalProgress()}
        />
      )}

      {isShowExpectationsModal && (
        <ExpectationsModal
          closeModal={() => setIsShowExpectationsModal(false)}
          expectations={expectations}
        />
      )}
    </div>
  );
};

export default PerformancePlanning;
