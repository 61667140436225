import React from "react";

import { Switch, Route } from "react-router";
import { pure } from "recompose";
import Cookies from "js-cookie";

import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../components/pages/Dashboard";
import MdaSetup from "../components/pages/MdaSetup";
import AppraisalDashboard from "../components/pages/AppraisalDashboard/AppraisalDashboard";
import AppraisalPeriod from "../components/pages/Settings/AppraisalPeriod/appraisalPeriod";
import PlanningApprovers from "../components/pages/Settings/PlanningApprovers/planningApprovers";
import AppraisalApprovers from "../components/pages/Settings/AppraisalApprovers/appraisalApprovers";
import AppraisalRating from "../components/pages/Settings/AppraisalRating/appraisalRating";
import CreateCompetencies from "../components/pages/Settings/CreateCompetency/createCompetency";
import PerformancePlanning from "../components/pages/Settings/AppraisalPeriod/PerformancePlanning";
import PlanningReview from "../components/pages/Settings/AppraisalPeriod/PlanningReview";
import QuarterlyEntry from "../components/pages/QuarterlyEntry/QuarterlyEntry";
import QuarterlyReview from "../components/pages/QuarterlyReview/QuarterlyReview";
import ApprovedList from "../components/pages/Approved/Approved";

export default pure(() => (
  <Switch>
    <ProtectedRoute path="/admin-portal/" exact component={Dashboard} />
    <ProtectedRoute path="/admin-portal/mda-setup" exact component={MdaSetup} />
    <ProtectedRoute
      path="/admin-portal/appraisal-dashboard"
      exact
      component={AppraisalDashboard}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-period"
      exact
      component={AppraisalPeriod}
    />

    <ProtectedRoute
      path="/admin-portal/planning-review"
      exact
      component={PlanningReview}
    />
    <ProtectedRoute
      path="/admin-portal/performance-planning"
      exact
      component={PerformancePlanning}
    />
    <ProtectedRoute
      path="/admin-portal/planning-approvers"
      exact
      component={PlanningApprovers}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-approvers"
      exact
      component={AppraisalApprovers}
    />
    <ProtectedRoute
      path="/admin-portal/quarterly-entry"
      exact
      component={QuarterlyEntry}
    />
    <ProtectedRoute
      path="/admin-portal/quarterly-review"
      exact
      component={QuarterlyReview}
    />
    <ProtectedRoute
      path="/admin-portal/appraisal-rating"
      exact
      component={AppraisalRating}
    />
    <ProtectedRoute
      path="/admin-portal/create-competency"
      exact
      component={CreateCompetencies}
    />
    <ProtectedRoute
      path="/admin-portal/approved-list"
      exact
      component={ApprovedList}
    />
    <ProtectedRoute
      path="/admin-portal/logout"
      exact
      component={() => {
        Cookies.remove("userId");
        Cookies.remove("_Role");
        Cookies.remove("_Email");
        Cookies.remove("profile");
        Cookies.remove("_FullName");
        Cookies.remove("firstName");
        Cookies.remove("lastName");
        Cookies.remove("fullName");
        Cookies.remove("profilePicture");
        Cookies.remove("isAdmin");
        Cookies.remove("loginTime");

        Cookies.remove("_APIBaseURL");
        Cookies.remove("tokenExist");

        localStorage.clear();
        window.location.assign(Cookies.get("homeLink"));
      }}
    />
    <ProtectedRoute
      path="/admin-portal/home"
      exact
      component={() => {
        Cookies.remove("userId");
        Cookies.remove("_Role");
        Cookies.remove("_Email");
        Cookies.remove("profile");
        Cookies.remove("_FullName");
        Cookies.remove("firstName");
        Cookies.remove("lastName");
        Cookies.remove("fullName");
        Cookies.remove("profilePicture");
        Cookies.remove("isAdmin");
        Cookies.remove("loginTime");
        Cookies.remove("isSupervisor");
        Cookies.remove("_APIBaseURL");
        Cookies.remove("tokenExist");

        localStorage.clear();
        window.location.assign(Cookies.get("homeLink"));
      }}
    />
    <Route render={() => <h1>Error 404. Page not found.</h1>} />
  </Switch>
));
