import React, { useEffect, useState } from "react";

import { Input } from "antd";
import { HiEye } from "react-icons/hi";
import LocationDetails from "../../../../layouts/LocationDetails";
import {
  getCurrentPeriod,
  getPerformanceDetailApprovalStafflist,
  postApprovePerformance,
} from "../../../../../network/services";
import { feedback } from "../../../../../utils/feedback";
import IsLoading from "../../../../common/isLoading";
import PlanningGoalsModal from "../../../../layouts/PlanningGoalsModal";
import CustomSearch from "../../../../common/CustomSearch/customSearch";

const { Search } = Input;

const PlanningReview = ({}) => {
  const [showGoalFormModal, setshowGoalFormModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [isFetchingAprovalList, setIsFetchingApprovalList] = useState(false);

  const [approvalList, setApprovalList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Performance Planning Review";
  }, []);

  const fetchCurrentPeriod = async () => {
    try {
      const response = await getCurrentPeriod();
      if (response?.status === 200) {
        const currentPeriodId = response?.data?.data?.id;
        fetchApprovalStaffList(currentPeriodId);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {}
  };

  const fetchApprovalStaffList = async (id) => {
    try {
      setIsFetchingApprovalList(true);
      const response = await getPerformanceDetailApprovalStafflist(id);
      if (response?.status === 200) {
        setApprovalList(response?.data?.data);
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
    setIsFetchingApprovalList(false);
  };

  const handleApprove = async (item) => {
    try {
      const response = await postApprovePerformance({
        performanceID: item?.id,
        approvalStatus: 1,
        comment: "approve",
      });
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchApprovalStaffList();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const declineApprove = async (item) => {
    try {
      const response = await postApprovePerformance({
        performanceID: item?.id,
        approvalStatus: 0,
        comment: "decline",
      });
      if (response?.status === 200) {
        feedback({
          title: "Success",
          text: response?.data?.message,
          iconType: "success",
        });
        fetchApprovalStaffList();
      } else {
        feedback({
          title: "Failed",
          text: response?.data?.message,
          iconType: "error",
        });
      }
    } catch (error) {
      feedback({
        title: "Failed",
        text: error?.response?.data?.message,
        iconType: "error",
      });
    }
  };

  const handleReviewGoals = async (item) => {
    setSelectedRecord(item);
    setshowGoalFormModal(true);
  };

  const handleSearch = (stringInQuestion) => {
    const result = approvalList.filter(
      (item) =>
        item.staffName === stringInQuestion || item.mdaName === stringInQuestion
    );

    return setApprovalList(result);
  };

  useEffect(() => {
    fetchCurrentPeriod();
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Performance Planning Review</h2>
        <LocationDetails />
      </div>

      <CustomSearch
        name="firstName"
        label="Director's First Name"
        placeholder="Enter First Name"
        type="text"
        required
      />

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p className="san-sherif">MDA Name</p>
            </div>
            <div className="th">
              <p className="san-sherif">Staff Name</p>
            </div>
            <div className="th">
              <p className="san-sherif">Status</p>
            </div>

            <div className="th">
              <p className="san-sherif" style={{ textAlign: "center" }}>
                Review Planning
              </p>
            </div>
            <div className="th">
              <p className="san-sherif" style={{ textAlign: "center" }}></p>
            </div>
            <div className="th"></div>
          </div>
        </div>
        <div className="table-body">
          {isFetchingAprovalList ? (
            <IsLoading />
          ) : (
            <>
              {approvalList &&
                approvalList.map((item) => (
                  <div className="table-row" key={item?.id}>
                    <div className="td">{item?.mdaName}</div>
                    <div className="td">{item?.staffName}</div>
                    <div className="td">{item?.approvalStatus}</div>
                    <div className="td" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className="pointer review-goals"
                        onClick={() => handleReviewGoals(item)}
                      >
                        <HiEye />
                      </button>
                    </div>
                    <div className="td" style={{ textAlign: "center" }}></div>
                    <div className="td actions">
                      <button
                        type="button"
                        className="btn btn-large w-100 activate"
                        style={{ marginRight: "5px" }}
                        onClick={() =>
                          feedback(
                            {
                              isDialog: true,
                              title: "Planning Review",
                              text: "You are about to approve the selected performance planning record",
                              confirmButtonText: "Proceed",
                              iconType: "warning",
                            },
                            () => handleApprove(item)
                          )
                        }
                      >
                        Approve
                      </button>
                      <button
                        type="button"
                        className="btn btn-large w-100 deactivate"
                        onClick={() =>
                          feedback(
                            {
                              isDialog: true,
                              title: "Planning Review",
                              text: "You are about to decline the selected performance planning record",
                              confirmButtonText: "Decline",
                              iconType: "warning",
                            },
                            () => declineApprove(item)
                          )
                        }
                      >
                        Decline
                      </button>
                    </div>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>

      {showGoalFormModal && (
        <PlanningGoalsModal
          closeModal={() => setshowGoalFormModal(false)}
          selectedRecord={selectedRecord}
        />
      )}
    </div>
  );
};

export default PlanningReview;
