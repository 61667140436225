import React, { useEffect, useState } from "react";
import { RiAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import { GiTrashCan } from "react-icons/gi";
import { AiOutlinePoweroff } from "react-icons/ai";

import LocationDetails from "../../../layouts/LocationDetails";
import ProfileForm from "../../../layouts/ProfileForm";

const AppraisalApprovers = ({ history, details = {} }) => {
  const [showFormModal, setShowFormModal] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "EDSG - Employee Profile";
  }, []);

  return (
    <div className="w-100">
      <div className="w-100 flex space-between flex-v-center">
        <h2>Setup Appraisal Approvers</h2>
        <LocationDetails />
      </div>

      <div className="table-view m-t-10">
        <div className="table-header">
          <div className="table-row">
            <div className="th">
              <p className="san-sherif">Role</p>
            </div>
            <div className="th">
              <p className="san-sherif">Approver</p>
            </div>
            <div className="th">
              <p className="san-sherif">Sequence</p>
            </div>
            <div className="th">
              <p className="san-sherif">Last Updated By</p>
            </div>
            <div className="th">
              <button
                className="add-button pointer"
                type="button"
                onClick={() => setShowFormModal(true)}
              >
                <RiAddLine />
                Add Approver
              </button>
            </div>
          </div>
        </div>
        <div className="table-body">
          {[{ id: 1 }, { id: 2 }].map((item) => (
            <div className="table-row" key={item.id}>
              <div className="td">ASSISTANT CHIEF STORES EXAMINER</div>
              <div className="td">MAURICE EWA</div>
              <div className="td">3</div>
              <div className="td">EdoGov Admin 06-Jan-2022 12:31:45 PM</div>
              <div className="td actions">
                <button
                  type="button"
                  className="pointer"
                  onClick={() => setShowFormModal(true)}
                >
                  <BiEditAlt />
                </button>
                <button type="button" className="pointer power">
                  <AiOutlinePoweroff />
                </button>
                <button type="button" className="del pointer">
                  <GiTrashCan />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      {showFormModal && (
        <ProfileForm closeModal={() => setShowFormModal(false)} />
      )}
    </div>
  );
};

export default AppraisalApprovers;
