import React from "react";

import { Input } from "antd";

import "./customSearch.scss";

const { Search } = Input;

const CustomSearch = ({ onSearch, placeholder }) => {
  return (
    <Search
      placeholder={placeholder}
      enterButton="Search"
      size="large"
      onSearch={onSearch}
      className="custom-search"
    />
  );
};

export default CustomSearch;
