export const getTotalWeight = (commitments) => {
  if (commitments?.length > 0) {
    const totalWeight = commitments.reduce(
      (previous, current) => previous + current.weight,
      0
    );
    return totalWeight;
  }

  return 0;
};
